import request from "./request.js";

export default function searchLibTag(access_token, key, limit, organization) {
    return request({
        url: "/library",
        method: "get",
        headers: {
            access_token
        },
        params: {
            key,
            limit,
            organization,
        },
    });
}
