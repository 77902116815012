/*
 * @Author: Charles Zhang
 * @Date: 2021-03-13 15:38:30
 * @Last Modified by: Garywoner
 * @Last Modified time: 2021-04-26 19:02:00
 */

import React, { Component } from "react";
import cookie from "react-cookies";
import PubSub from "pubsub-js";

import { Card, Button, Table, MessageBox } from "element-react";

import getDocumentBody from "api/getDocumentBody";
import ssoRefresh from "../../api/ssoRefresh";

export default class BatchResult extends Component {
  state = {
    columns: [],
    documentBody: [],
  };

  documentCache = {};

  resultBlockDict = {};

  onClick = (image) => {
    return () => {
      MessageBox.alert(
        <div style={{height: "500px", overflow: "auto"}}>
          <img alt={"【！！！ 图片未正确显示 ！！！】"} src={image} />
        </div>,
        "图片预览",
        { showConfirmButton: false }
      )
        .then(() => {})
        .catch(() => {});
    };
  };

  handleRowClassName = (row) => {
    if (row.block_no in this.resultBlockDict) {
      return "warning-row";
    } else {
      return "normal-row";
    }
  };
  handleColums = () => {
    const { title } = this.props;

    let title_list = title.split(".");
    if(title_list[title_list.length - 1] === "cgcode"){
      this.setState({ columns: [
          {
            type:"expand",
            expandPannel: (data) => {
              return (
                  <pre className="font-weight mb-0" style={{ color: "#005bd9" }}>
                {data.content.replace(data.content.split("\n")[0] + "\n", "")}
              </pre>
              );
            },
          },
          {
            label: "代码定位",
            prop: "codeLocate",
            render: (data) => {
              return (<h7 className="font-weight-bold mb-1">
                {data.content.split("\n")[0]}
              </h7>);
            }
          },
        ] });

    }
    else{
      this.setState({ columns: [
          {
            label: "文档",
            prop: "content",
            render: (data) => {
              const blockType = data.block_type;

              if (blockType === "text") {
                return data.content;
              } else if (blockType === "code") {
                return (
                    <pre className="font-weight mb-0" style={{ color: "#005bd9" }}>
                      {data.content}
                    </pre>
                );
              } else if (blockType === "image") {
                return (
                    <div style={{ cursor: "pointer" }}>
                      <img
                          alt={"【！！！ 图片未正确显示 ！！！】"}
                          src={data.content}
                          height={100}
                          onClick={this.onClick(data.content)}
                      />
                    </div>
                );
              } else {
                return `未能识别当前行的类型: ${data.block_type}`;
              }
            },
          },
        ] });
    }
  }
  componentDidMount() {
    this.setState({ documentBody: [] });

    // 先取消订阅，避免重复触发的问题
    PubSub.unsubscribe("fetchBatchDocument");
    PubSub.subscribe("fetchBatchDocument", (_, stateObj) => {
      const id = stateObj.id;

      if (id in this.documentCache) {
        this.setState({ documentBody: this.documentCache[id] });
        console.log(`文档 ${id} 获取命中缓存！`);
        this.handleColums();
        return;
      }

      getDocumentBody(cookie.load("ACCESS_TOKEN"), id)
        .then((response) => {
          try{
            if(response.data.data.message === "logout") {
              ssoRefresh();
            }
          }
          catch(e){
            console.log(e);
          }
          if (!response.data.data.body) {
            this.setState({ documentBody: [] });
          } else {
            this.documentCache[id] = response.data.data.body;
            this.setState({ documentBody: response.data.data.body });
          }
          this.handleColums();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  render() {
    const { columns, documentBody } = this.state;

    const { title, switchDetailToSummary } = this.props;

    return (
      <>
        <Card
          className="box-card"
          bodyStyle={{
            padding: 0,
            height: window.innerHeight - 330,
            overflow: "auto",
          }}
          header={
            <div className="clearfix">
              <span style={{ lineHeight: "36px" }} className="h6 text-muted">
                {title}
              </span>
              <span style={{ float: "right" }}>
                <Button type="primary" onClick={switchDetailToSummary}>
                  {"<返回列表"}
                </Button>
              </span>
            </div>
          }
        >
          <div className="doc-detail">
            <Table
              style={{ width: "100%", overflowX: "hidden" }}
              rowClassName={this.handleRowClassName}
              columns={columns}
              data={documentBody}
              showHeader={false}
              stripe={false}
              border={false}
            />
          </div>
        </Card>
      </>
    );
  }
}
