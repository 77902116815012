/*
 * @Author: Charles Zhang
 * @Date: 2021-03-09 22:55:48
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-13 20:06:11
 */

import React, { Component } from "react";
import cookie from "react-cookies";
import PubSub from "pubsub-js";

import { Layout, Card } from "element-react";

import BatchResultCluster from "./BatchResultCluster";
import BatchResult from "./BatchResult";
import MiniCard from "./MiniCard";

import getBatchDetail from "api/getBatchDetail";
import ssoRefresh from "../../api/ssoRefresh";

export default class BatchBoard extends Component {
  state = {
    result: [],
    clusterList: [],
    isClusterResultLoading: true,
    summaryVisible: true,
    detailVisible: false,
    nowChooseDocumentID: "",
    nowChooseDocumentTitle: "",
  };

  resultCache = {};

  switchSummaryToDetail = (doc_id, title) => {
    this.setState({
      summaryVisible: false,
      detailVisible: true,
      nowChooseDocumentID: doc_id,
      nowChooseDocumentTitle: title,
    });
  };

  switchDetailToSummary = () => {
    this.setState({
      summaryVisible: true,
      detailVisible: false,
    });
  };

  componentDidMount() {
    // 先取消订阅，避免重复触发的问题
    PubSub.unsubscribe("fetchBatchResult");
    PubSub.subscribe("fetchBatchResult", (_, stateObj) => {

      this.setState({ clusterList: []});

      this.setState({
        summaryVisible: true,
        detailVisible: false,
      });

      const batchID = stateObj.id;

      if (batchID in this.resultCache) {
        console.log(`批次：${batchID} 命中缓存！`);
        this.setState({
          ...this.resultCache[batchID],
          isClusterResultLoading: false,
        });
        return;
      }

      getBatchDetail(cookie.load("ACCESS_TOKEN"), batchID).then((response) => {
        try{
          if(response.data.data.message === "logout") {
            ssoRefresh();
          }
        }
        catch(e){
          console.log(e);
        }
        const documents = response.data.data.documents;
        const clusterList = [];

        const docList = [];
        for(let doc in documents){
          docList.push(documents[doc]);
        }

        clusterList.push({
          cluster: batchID,
          documents: docList,
        });

        const resultData = {
          clusterList,
        };

        this.resultCache[batchID] = resultData;

        this.setState({
          ...resultData,
          isClusterResultLoading: false,
        });
      });
    });
  }

  render() {
    const {
      clusterList,
      nowChooseDocumentID,
      nowChooseDocumentTitle,
      isClusterResultLoading,
      summaryVisible,
      detailVisible,
    } = this.state;

    let { statistics } = this.state;

    const { data } = this.props;

    return (
      <>
        <Layout.Row className="text-center mb-3">
          <Layout.Col span="24">
            <Card className="box-card">
              <Layout.Row gutter="10">
                <Layout.Col lg="6" md="5">
                  <MiniCard title="批次ID" content={data.batch_id} />
                </Layout.Col>
                <Layout.Col lg="18" md="3">
                  <MiniCard title="批次名称" content={data.name} />
                </Layout.Col>
              </Layout.Row>
            </Card>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row className="text-center mb-3">
          <Layout.Col span="24">
            <Card className="box-card">
              <Layout.Row gutter="10">
                <Layout.Col lg="6" md="5">
                  <MiniCard title="文件数量" content={data.total} />
                </Layout.Col>
                <Layout.Col lg="18" md="3">
                  <MiniCard title="库标签" content={data.lib_tag?data.lib_tag:"该批次未入库！"} />
                </Layout.Col>
              </Layout.Row>
            </Card>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row gutter="20">
          <div style={{ display: summaryVisible ? "block" : "none" }}>
            <Layout.Col span="24">
              <BatchResultCluster
                clusterList={clusterList}
                isClusterResultLoading={isClusterResultLoading}
                switchSummaryToDetail={this.switchSummaryToDetail}
              />
            </Layout.Col>
          </div>
          <div style={{ display: detailVisible ? "block" : "none" }}>
            <Layout.Col span="24">
              <BatchResult
                id={nowChooseDocumentID}
                title={nowChooseDocumentTitle}
                switchDetailToSummary={this.switchDetailToSummary}
              />
            </Layout.Col>
          </div>
        </Layout.Row>
      </>
    );
  }
}
