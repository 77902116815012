import request from "./request.js";

export default function getBatchIdByLibTag(access_token, lib_tag) {
    return request({
        url: "/library",
        method: "post",
        headers: {
            access_token,
        },
        params: {
            lib_tag,
        },
    });
}
