import request from "./requestLongTimeOut.js";

export default function getCheckResult(access_token, job_id) {
  return request({
    url: "/check",
    method: "get",
    headers: {
      access_token,
    },
    params: {
      job_id,
    },
  });
}
