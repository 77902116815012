/*
 * @Author: Charles Zhang
 * @Date: 2021-03-13 14:03:37
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-13 19:37:29
 */

import React, { Component } from "react";
import PubSub from "pubsub-js";

import { Card, Button, Table, Loading } from "element-react";

import cookie from "react-cookies";

export default class CheckResultCluster extends Component {
  state = {
    columns: [
      {
        label: "类别",
        prop: "cluster",
        width: 80,
      },
      {
        label: "文档",
        prop: "documents",
        render: (data) => {
          const { Medium2HighStatistic, Low2MediumStatistic } = this.props;
          return (
            <div className="my-3">
              {data.documents.map((docObj) => {
                let buttonType = "success";

                const id = docObj.doc_id;
                const title = docObj.title;
                const repetitiveRate = (docObj.repetitive_rate * 100).toFixed(
                  0
                );

                const { switchSummaryToDetail } = this.props;

                // TODO 此处加上一个基于统计学的抄袭阈值分析算法
                // 并在页面中作图展示
                if (repetitiveRate >= Medium2HighStatistic) {
                  buttonType = "danger";
                } else if (repetitiveRate >= Low2MediumStatistic) {
                  buttonType = "warning";
                }

                return (
                  <Button
                    key={id}
                    size="mini"
                    className="ml-0 mr-1 my-2"
                    type={buttonType}
                    onClick={() => {
                      PubSub.publish("fecthDocument", { id });
                      switchSummaryToDetail(id, title);
                    }}
                  >
                    {`${title} (${repetitiveRate}%)`}
                  </Button>
                );
              })}
            </div>
          );
        },
      },
    ]
  };


  isNumber(val) {
    let regPos = /^\d+(\.\d+)?$/; //非负浮点数
    let regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
    return regPos.test(val) || regNeg.test(val);
  }

  render() {
    const { columns } = this.state;

    const { clusterList, isClusterResultLoading } = this.props;

    return (
      <>
        <Card
          className="box-card"
          bodyStyle={{
            padding: 0,
            height: window.innerHeight - 310,
            overflow: "auto",
          }}
          header={
            <div className="clearfix">
              <span style={{ lineHeight: "36px" }} className="h6 text-muted">
                聚类结果
              </span>
              <span style={{ float: "right" }}>
                {/*<Button type="primary">重新加载</Button>*/}
              </span>
            </div>
          }
        >
          <Loading text="正在下载查重报告" loading={isClusterResultLoading}>
            <Table
              style={{ width: "100%", overflowX: "hidden" }}
              columns={columns}
              data={clusterList}
              showHeader={false}
              stripe={true}
              border={true}
            />
          </Loading>
        </Card>
      </>
    );
  }
}
