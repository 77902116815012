import request from "./request.js";

export default function deleteResult(access_token, job_id) {
  return request({
    url: "/check",
    method: "delete",
    headers: {
      access_token,
    },
    data: {
      job_id,
    },
  });
}
