import axios from "axios";
import defaultSettings from "./settings.js";

import { Notification } from "element-react";

// create an axios instance
const service = axios.create({
  baseURL: defaultSettings.baseUrl, // url = base url + request url
  timeout: 30000, // request timeout
  validateStatus: (status) => {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
});

service.interceptors.request.use(
  (config) => {
    // 在发送请求前做些什么
    return config;
  },
  (err) => {
    // 在请求错误的时候的逻辑处理
    Notification.error({
      showClose: true,
      message: "抱歉，系统发生了未知错误 (001)！",
    });
    return Promise.reject(err);
  }
);

service.interceptors.response.use(
  (res) => {
    // 在请求成功后的数据处理
    return res;
  },
  (err) => {
    // 在响应错误的时候的逻辑处理
    console.log(err);
    if (err.response) {
      const res = err.response.data
      console.log('1111111111')
      console.log(res)
      if (res && res.status) {
        console.log(res.status)
      }
      console.log('22222222222')
    }
    Notification.error({
      showClose: true,
      message: "抱歉，系统发生了未知错误 (002)！",
    });
    return Promise.reject(err);
  }
);

export default service;
