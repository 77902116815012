import request from "./request.js";

export default function deleteBatch(access_token, batch_id) {
  return request({
    url: "/batch",
    method: "delete",
    headers: {
      access_token,
    },
    data: {
      batch_id,
    },
  });
}
