import cookie from "react-cookies";
import defaultSettings from "./settings";
import {Message} from "element-react";

export default function ssoRefresh(back_home=true) {
    const refresh_token = cookie.load("REFRESH_TOKEN");
    if(refresh_token === "undefined"){
        if(back_home) {
            Message({
                type: 'warning',
                message: '请重新验证登录。'
            });
            setTimeout(function(){
                window.location.href = "/";
            }, 2000);
            // window.location.href = "/";
        }
        else {
            cookie.remove("ACCESS_TOKEN", { path: "/" });
            cookie.remove("REFRESH_TOKEN", { path: "/" });
            cookie.save("isLogin", false, { path: "/" });
            cookie.remove("loginKey", { path: "/" });
            cookie.remove("organization", { path: "/" });
            return;
        }
    }
    // const xhr = new XMLHttpRequest();
    // xhr.open('POST', 'https://sso.eduxiji.com/auth/realms/CG/protocol/openid-connect/token', false);
    // xhr.setRequestHeader("Content-Type","application/x-www-form-urlencoded;charset=UTF-8");
    // xhr.send('grant_type=refresh_token&client_id=cgplag&refresh_token=' + refresh_token);
    // const res = JSON.parse(xhr.responseText);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', defaultSettings.baseUrl + '/sso_relay', false);
    xhr.setRequestHeader("Content-Type","application/x-www-form-urlencoded;charset=UTF-8");
    xhr.send('request_action=refresh&refresh_token=' + refresh_token);
    const res = JSON.parse(xhr.responseText).data;

    try{
        cookie.save("ACCESS_TOKEN", res["access_token"], { path: "/" });
        cookie.save("REFRESH_TOKEN", res["refresh_token"], { path: "/" });
        cookie.save("isLogin", true, { path: "/" });
        // alert("时间过长，请重新操作。");
        // Message({
        //     type: 'warning',
        //     message: '时间过长，请重新操作。'
        // });
        // setTimeout(function(){
        //     window.location.reload([true]);
        // }, 2000);
        window.location.href = "/";
    }
    catch(e){
        cookie.remove("ACCESS_TOKEN", { path: "/" });
        cookie.remove("REFRESH_TOKEN", { path: "/" });
        cookie.save("isLogin", false, { path: "/" });
        cookie.remove("loginKey", { path: "/" });
        cookie.remove("organization", { path: "/" });
        // alert("登录已失效，请重新登录。");
        Message({
            type: 'warning',
            message: '登录已失效，请重新登录。'
        });

        setTimeout(function(){
            window.location.href="/";
        }, 2000);
    }
}
