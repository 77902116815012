import request from "./request.js";

export default function createCheckJob(
  access_token,
  batch_id,
  job_name,
  search_range,
  with_self
) {
  return request({
    url: "/check",
    method: "post",
    headers: {
      access_token,
    },
    data: {
      batch_id,
      job_name,
      search_range,
      with_self
    },
  });
}
