/*
 * @Author: Charles Zhang
 * @Date: 2021-02-20 16:29:47
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-30 16:37:58
 */

import React, { Component } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

// core components
import DemoNavbar from "components/Navbar";
import CardsFooter from "components/Footer";

// argon style
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

// element style
import "element-theme-default";

// custom style
import "assets/css/cgplag-style.css"

import Home from "pages/Home";
import Check from "pages/Check";
import Check_code from "pages/Check_code";
import Result from "pages/Result";
import Update_lib from "pages/Update_lib";
import Check_report from "pages/Check_report";

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <DemoNavbar />
        <Switch>
          <Route path="/" exact render={(props) => <Home {...props} />} />
          <Route path="/check" render={(props) => <Check {...props} />} />
          <Route path="/check_code" render={(props) => <Check_code {...props} />} />
          <Route path="/result" render={(props) => <Result {...props} />} />
          <Route path="/update_lib" render={(props) => <Update_lib {...props} />} />
          <Route path="/check_report" render={(props) => <Check_report {...props} />} />
          <Redirect to="/" />
        </Switch>
        <CardsFooter />
      </BrowserRouter>
    );
  }
}
