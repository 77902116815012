/*
 * @Author: Charles Zhang
 * @Date: 2021-02-21 10:09:21
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-16 23:17:27
 */

//  TODO Check和Result组件都需要提前验证是否已经登录

import React, { Component } from "react";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// element-react components
import { Button } from "element-react";

// custom components
import Description from "components/Description";
import Background from "components/Background";

import Banner from "./Banner";
import LoadTable from "./LoadTable";
import BatchTable from "./BatchTable";
import CheckTable from "./CheckTable";
import ResultTable from "./ResultTable";

import "./index.css";

export default class Result extends Component {
  leftBatchColumns = [
    {
      label: "批次名称",
      prop: "name",
    },
    {
      label: "数量",
      prop: "total",
      width: 70,
    },
    {
      label: "创建时间",
      prop: "time",
      width: 180,
      sortable: true,
    },
  ];

  rightResultColumns = [
    {
      label: "查重任务标题",
      prop: "name",
      render: (data) => {
        return (
          <span style={{ cursor: "pointer" }} onClick={data.operation.show}>
            {data.name}
          </span>
        );
      },
    },
    {
      label: "数量",
      prop: "total",
      width: 70,
    },
    {
      label: "创建时间",
      prop: "craeteTime",
      width: 180,
      sortable: true,
    },
  ];

  state = {
    leftWidth: 6,
    rightWidth: 6,
    leftShow: "block",
    rightShow: "block",
    leftBatchColumns: this.leftBatchColumns,
    rightResultColumns: this.rightResultColumns,
  };

  handleLeftUnflod = () => {
    this.setState({
      leftWidth: 12,
      rightShow: "none",
      leftBatchColumns: [
        {
          label: "#",
          prop: "index",
          width: 60,
        },
        {
          label: "批次ID",
          prop: "batch_id",
          width: 90,
          render: (data) => <code>{data.batch_id}</code>,
        },
        {
          label: "是否入库",
          prop: "lib_tag",
          width: 100,
          render: (data) => <div>{data.lib_tag?"已入库":"否"}</div>,
        },
        {
          label: "入库类型",
          prop: "organization",
          width: 100,
          render: (data) => <div>{data.lib_tag?(data.organization?"私有库":"公有库"):"无"}</div>,
        },
        {
          label: "批次名称",
          prop: "name",
        },
        {
          label: "数量",
          prop: "total",
          width: 70,
        },
        {
          label: "创建时间",
          prop: "time",
          width: 170,
          sortable: true,
        },
        {
          label: "操作",
          prop: "operation",
          width: 200,
          render: (data) => {
            return (
              <span>
                <Button
                  type="success"
                  size="small"
                  onClick={data.operation.check}
                >
                  查重
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={data.operation.show}
                >
                  查看
                </Button>
                <Button
                  type="danger"
                  size="small"
                  onClick={data.operation.delete}
                >
                  删除
                </Button>
              </span>
            );
          },
        },
      ],
    });
  };

  handlerLeftFlod = () => {

    this.setState({
      leftWidth: 6,
      rightShow: "block",
      leftBatchColumns: this.leftBatchColumns,
    });
  };

  handleRightUnflod = () => {
    this.setState({
      rightWidth: 12,
      leftShow: "none",
      rightResultColumns: [
        {
          label: "#",
          prop: "index",
          width: 60,
        },
        {
          label: "任务ID",
          prop: "id",
          render: (data) => <code>{data.id}</code>,
          width: 180,
        },
        {
          label: "查重任务标题",
          prop: "name",
          render: (data) => {
            return (
              <span style={{ cursor: "pointer" }} onClick={data.operation.show}>
                {data.name}
              </span>
            );
          },
        },
        {
          label: "数量",
          prop: "total",
          width: 70,
        },
        {
          label: "查重用时",
          prop: "totalTime",
          width: 100,
        },
        {
          label: "创建时间",
          prop: "craeteTime",
          width: 180,
          sortable: true,
        },
        {
          label: "操作",
          prop: "operation",
          width: 150,
          render: (data) => {
            return (
              <span>
                <Button
                  type="primary"
                  size="small"
                  onClick={data.operation.show}
                >
                  查看
                </Button>
                <Button
                  type="danger"
                  size="small"
                  onClick={data.operation.delete}
                >
                  删除
                </Button>
              </span>
            );
          },
        },
      ],
    });
  };

  handlerRightFlod = () => {
    this.setState({
      rightWidth: 6,
      leftShow: "block",
      rightResultColumns: this.rightResultColumns,
    });
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.main.scrollTop = 0;
  }

  render() {
    const {
      leftWidth,
      rightWidth,
      leftShow,
      rightShow,
      leftBatchColumns,
      rightResultColumns,
    } = this.state;

    const descriptionProps = {
      title: "文档管理 打造专属查重数据库",
      subtitle: "基于ElasticSearch的文档特征索引技术",
      description:
        "在这里可以查看历史提交的文档和查重报告；" +
        "自动解析您提交的压缩文件，其中的每篇文档都将完美呈现；" +
        "查重报告聚类展示，轻松发现聚集抄袭现象；" +
        "已提交文档无需重复上传，随时改变查重范围！",
      buttons: [
        {
          // to: "/help",
          to: "/",
          color: "info",
          icon: "fa fa-arrow-circle-left",
          title: "返回主页",
        },
        {
          to: "/check",
          color: "secondary",
          icon: "fa fa-rocket",
          title: "继续提交文档",
        },
        {
          to: "/check_code",
          color: "secondary",
          icon: "fa fa-flash",
          title: "继续提交代码包",
        },
      ],
    };

    return (
      <div>
        <main className="profile-page" ref={(c) => (this.main = c)}>
          {/* Part1 Description and Background */}
          <div className="position-relative">
            <section className="section section-lg section-shaped pb-250">
              <Background/>
              <Description {...descriptionProps} />
            </section>
          </div>

          {/* Part2 Job Result Card */}
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Card className="card-profile shadow mt-0">
                <div className="px-4">
                  <div className="my-3">
                    <Row className="justify-content-center">
                      <Col lg={leftWidth} style={{ display: leftShow }}>
                        <Row>
                          <Col>
                            <Banner
                              content="当 前 提 交 进 度"
                              color="success"
                              icon="fa fa-cloud-upload"
                              handleUnflod={this.handleLeftUnflod}
                              handleFlod={this.handlerLeftFlod}
                            />
                            <Card className="shadow mt-3">
                              <LoadTable/>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mt-4">
                              <Banner
                                content="提 交 及 入 库 文 件 管 理"
                                color="primary"
                                icon="fa fa-archive"
                                handleUnflod={this.handleLeftUnflod}
                                handleFlod={this.handlerLeftFlod}
                              />
                            </div>
                            <Card className="shadow mt-3">
                              <BatchTable
                                columns={leftBatchColumns}
                                handleFlod={this.handlerLeftFlod}
                              />
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={rightWidth} style={{ display: rightShow }}>
                        <Row>
                          <Col>
                            <Banner
                              content="查 重 任 务 管 理"
                              color="danger"
                              icon="fa fa-bolt"
                              handleUnflod={this.handleRightUnflod}
                              handleFlod={this.handlerRightFlod}
                            />
                            <Card className="shadow mt-3">
                              <CheckTable/>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mt-4">
                              <Banner
                                content="查 重 报 告 管 理"
                                color="warning"
                                icon="fa fa-file-text"
                                handleUnflod={this.handleRightUnflod}
                                handleFlod={this.handlerRightFlod}
                              />
                            </div>
                            <Card className="shadow mt-3">
                              <ResultTable columns={rightResultColumns}/>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
      </div>
    );
  }
}
