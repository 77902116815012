import request from "./request.js";

// 半持久化记录该access_token对应的token，用于导出的查重报告的查看
export default function postCheckToken(access_token) {
  return request({
    url: "/progress",
    method: "post",
    headers: {
      access_token,
    },
    params: {
    },
  });
}
