/*
 * @Author: Charles Zhang
 * @Date: 2021-02-21 11:02:23
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-01 15:04:20
 */

import React, { Component } from "react";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// custom components
import Description from "components/Description";
import Background from "components/Background";

import CreateJobForm from "./CreateJobForm";

export default class Check extends Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.main.scrollTop = 0;
  }

  render() {
    const descriptionProps = {
      title: "提交代码包 立刻开始查重",
      subtitle: "代码交叉比对技术",
      description:
        "支持代码打包交叉查重；" +
        "支持自定义查重来源库；" +
        "支持基于Louvain的查重结果聚类分析；" +
        "支持查重结果高速反馈！",
      buttons: [
        {
          // to: "/help",
          to: "/",
          color: "info",
          icon: "fa fa-arrow-circle-left",
          title: "返回主页",
        },
        {
          to: "/result",
          color: "secondary",
          icon: "fa fa-archive",
          title: "查看已提交文档/代码包",
        },
      ],
    };

    return (
      <main className="profile-page" ref={(c) => (this.main = c)}>
        {/* Part1 Description and Background */}
        <div className="position-relative">
          <section className="section section-lg section-shaped pb-250">
            <Background />
            <Description {...descriptionProps} />
          </section>
        </div>
        {/* Part2 Job Create Card */}
        <section className="section section-lg pt-lg-0 mt--200">
          <Container>
            <Card className="card-profile shadow">
              <Container className="my-3">
                <Row className="justify-content-center">
                  <Col lg="12">
                    <CreateJobForm />
                  </Col>
                </Row>
              </Container>
            </Card>
          </Container>
        </section>
      </main>
    );
  }
}
