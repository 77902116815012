/*
 * @Author: Charles Zhang
 * @Date: 2021-03-04 14:56:56
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-06 17:18:00
 */

import React, { Component } from "react";

import PubSub from "pubsub-js";

import { Alert } from "reactstrap";

export default class Banner extends Component {
  state = {
    unflodShow: "inline",
    flodShow: "none",
  };

  onUnflod = (handler) => {
    return () => {
      PubSub.publish("onUnflod");
      handler();
    };
  };

  onFlod = (handler) => {
    return () => {
      PubSub.publish("onFlod");
      handler();
    };
  };

  componentDidMount() {
    PubSub.subscribe("onUnflod", () => {
      this.setState({ unflodShow: "none", flodShow: "inline" });
    });
    PubSub.subscribe("onFlod", () => {
      this.setState({ unflodShow: "inline", flodShow: "none" });
    });
  }

  render() {
    const { unflodShow, flodShow } = this.state;

    const { content, color, icon, handleUnflod, handleFlod } = this.props;

    return (
      <div>
        <div id="table-banner">
          <Alert color={color} style={{ display: "flex" }}>
            <div
              style={{
                display: "inline-block",
                marginBottom: 0,
                width: "90%",
              }}
              className="h5 text-white text-center"
            >
              <i className={icon + " mr-3"} />
              {content}
            </div>

            <div
              style={{
                display: "inline-block",
                marginBottom: 0,
                width: "10%",
                margin: "auto",
              }}
              className="text-white text-right"
            >
              <span
                style={{
                  color: "white",
                  cursor: "pointer",
                  display: unflodShow,
                }}
                onClick={this.onUnflod(handleUnflod)}
              >
                {"展开>"}
              </span>
              <span
                style={{
                  color: "white",
                  cursor: "pointer",
                  display: flodShow,
                }}
                onClick={this.onFlod(handleFlod)}
              >
                {"<收起"}
              </span>
            </div>
          </Alert>
        </div>
      </div>
    );
  }
}
