/*
 * @Author: Charles Zhang
 * @Date: 2021-02-22 16:47:27
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-03 23:54:24
 */

import React, { Component } from "react";
import cookie from "react-cookies";

import { withRouter } from "react-router";

// reactstrap components
import { Button as BootButton, Row, Col, UncontrolledAlert } from "reactstrap";

// element-react components
import { Form, Input, Switch, Button, Dialog, Radio, Collapse } from "element-react";
//import { Button, Upload, Input, Dialog, Radio } from "element-react";
// custom components
import Upload from "components/Upload";
import Search_lib from "components/Search_lib";
import Search_lib_private from "components/Search_lib_private";

// custom js
import dateFormater from "utils/dateFormater";

// requests
import createBatch from "api/createBatch";
import craeteJointJob from "api/craeteJointJob";
import getBatchIdByLibTag from "api/getBatchIdByLibTag";
import ssoRefresh from "../../api/ssoRefresh";

class CreateJobForm extends Component {
  state = {
    needCheck: false,
    // 文档标题
    // batchTitle: "",
    // 任务标题
    jobTitle: "",
    file: [],
    file_format: "",
    submited: false,
    showWarrning: false,
    // 高级选项是否展开
    activeCollapse: [],
    lib_tag: "",
    lib_tag_p: "",
    private_lib: true,
    with_self: true,
    CheckType:1,
  };

  handleFormat = (value) =>{
    this.setState({ file_format: value });
  }

  submitPreCheck = () => {

      if (cookie.load("isLogin") !== "true") {
      alert("请点击右上角的“用户登录”按钮进行登录！");
      return false;
    }

    return true;
  };

  doCreateBatch = () => {
    // const { file, batchTitle ,file_format } = this.state;
    const { file, jobTitle, file_format } = this.state;

    createBatch(cookie.load("ACCESS_TOKEN"), file, file_format, jobTitle, "DESCRIPTION")
      .then((response) => {
        try{
          if(response.data.data.message === "logout") {
            ssoRefresh();
          }
        }
        catch(e){
          console.log(e);
        }
        const batchID = response.data.data.batch_id;
        this.setState({ submited: false });
        this.props.history.push("/result", { batchID });
      })
      .catch((err) => {
        console.log(err);
        alert(err);
        this.setState({ submited: false });
      });
  };

  doCreateJoint = () => {
    const { file, jobTitle, file_format, lib_tag, lib_tag_p, needCheck, private_lib, with_self } = this.state;

    let search_range = "";
    getBatchIdByLibTag(private_lib? cookie.load("ACCESS_TOKEN"):"", private_lib?lib_tag_p:lib_tag)
        .then((response) => {
          try{
            if(response.data.data.message === "logout") {
              ssoRefresh();
            }
          }
          catch(e){
            console.log(e);
          }
          search_range = response.data.data.result;

    craeteJointJob(cookie.load("ACCESS_TOKEN"), file, file_format, jobTitle, "", jobTitle, needCheck?search_range:"", with_self)
      .then((response) => {
        try{
          if(response.data.data.message === "logout") {
            ssoRefresh();
          }
        }
        catch(e){
          console.log(e);
        }
        const batchID = response.data.data.batch_id;
        const jobID = response.data.data.job_id;
        this.setState({ submited: false });
        this.props.history.push("/result", { batchID, jobID });
      })
      .catch((err) => {
        console.log(err);
        alert(err);
        this.setState({ submited: false });
      });
    })
    .catch((err) => {
      console.log(err);
      alert(err);
      this.setState({ submited: false });
    });
  };

  handleUpdateFile = (file) => {
    this.setState({ file });
  };

  handleUpdateLibTag = (lib_tag) => {
    this.setState({ lib_tag });
  };

  handleUpdateLibTagPrivate = (lib_tag_p) => {
    this.setState({ lib_tag_p });
  };

  handleSubmit = (isJoint) => {
    return () => {
      if (!this.submitPreCheck()) {
        return false;
      }
      if (this.state.needCheck && !(this.state.private_lib?this.state.lib_tag_p:this.state.lib_tag)) {
        alert("您已打开库查重，请添加对应的库标签！");
        return false;
      }

      this.setState({ submited: true });

      this.form.validate((valid) => {
        if (valid) {
          const { with_self,CheckType,file, jobTitle } = this.state;

          if (!file.uid) {
            this.setState({ submited: false, showWarrning: true });
            return false;
          }
          if (with_self===false && CheckType===1) {
            alert("无意义的查重任务！请添加对比的查重数据库或开启包内互查后再提交查重任务。");
            this.setState({ submited: false});
            return false;
          }
          // if (!batchTitle) {
          //   alert("请填写文档提交标题，或点击“自动生成标题”按钮！");
          //   this.setState({ submited: false });
          //   return false;
          // }

          if (isJoint) {
            if (!jobTitle) {
              alert("请填写查重任务标题，或点击“自动生成标题”按钮！");
              this.setState({ submited: false });
              return false;
            }
            this.doCreateJoint();
          } else {
            this.doCreateBatch();
          }
        } else {
          console.log("error submit!!");
          this.setState({ submited: false });
          return false;
        }
      });
    };
  };

  handleAutoTitle = (callbackFunc) => {
    const nowDate = dateFormater("yyyy-MM-dd_HH:mm:ss");

    if (typeof callbackFunc !== "function") {
      callbackFunc = () => {};
    }

    this.setState(
      {
        // batchTitle: `文档_${nowDate}`,
        jobTitle: `文档任务_${nowDate}`,
      },
      callbackFunc
    );
  };

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };

  onCheckChange = (key, value) => {
    if(value===1){
      this.setState({ needCheck: false });
      this.setState({ CheckType: 1 });
    }
    if(value===2){
      this.setState({ needCheck: true});
      this.setState({ private_lib: true});
      this.setState({ CheckType: 2 });
    }
    if(value===3){
      this.setState({ needCheck: true});
      this.setState({ private_lib: false});
      this.setState({ CheckType: 3 });
    }
  };


  onDismissWarning = () => {
    this.setState({ showWarrning: false });
  };

  render() {
    const {
      file,
      needCheck,
      submited,
      showWarrning,
      jobTitle,
      private_lib,
      with_self,
      activeCollapse,
    } = this.state;

    return (
      <div className="px-2">
        <UncontrolledAlert
          color="warning"
          fade={false}
          toggle={this.onDismissWarning}
          style={{ display: showWarrning ? "block" : "none" }}
        >
          <span className="alert-inner--icon">
            <i className="ni ni-air-baloon" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>请注意！</strong>{" "}
            您尚未上传需要查重的文档（点击“选择文件”按钮上传）！
          </span>
        </UncontrolledAlert>
        <Form ref={(c) => (this.form = c)} model={this.state}>
          <h4 className="text-primary font-weight-bold mb-4">
            {/*第一步：上传待查文档*/}
            上传待查文档
          </h4>
          <Form.Item prop="jobTitle">
            <Input
                value={this.state.jobTitle}
                style={{ cursor: "default" }}
                onChange={this.onChange.bind(this, "jobTitle")}
                placeholder="请输入本次查重任务名"
                prepend={
                  <div style={{ width: "100px", color: "#172b4d" }}>
                    查重任务名
                  </div>
                }
                append={
                  <Button type="primary" onClick={this.handleAutoTitle}>
                    自动生成
                  </Button>
                }
            />
          </Form.Item>
          <Form.Item prop="batchUpload">
            <Upload handleUpdateFile={this.handleUpdateFile} handleFormat={this.handleFormat}/>
          </Form.Item>

          <Collapse accordion value={this.state.activeCollapse} onChange={(e) => this.state.activeCollapse = e} >
            <Collapse.Item title="高级配置项（与历史文档进行查重对比）" name="1" >
              {/*<h4 className="text-primary font-weight-bold mb-4">*/}
              {/*  查重任务配置*/}
              {/*</h4>*/}
              <div>
                <Form.Item>
                  <br/>
                  <Row>
                    <Col lg="3" >
                      <div>
                        加入对比的查重数据库:
                      </div>
                    </Col>
                    <Col lg="4" >
                      <Radio.Group onChange={this.onCheckChange.bind(this, "CheckType")} value={this.state.CheckType}>
                        <Radio.Button value={1} checked={this.state.CheckType === 1}>无</Radio.Button>
                        <Radio.Button value={2} checked={this.state.CheckType=== 2}>私有库</Radio.Button>
                        <Radio.Button value={3} checked={this.state.CheckType === 3}>公有库</Radio.Button>
                      </Radio.Group>
                    </Col>
                    <Col lg="5" >
                      <div>
                        说明：提交的文档将与所选库中的历史文档进行查重对比
                      </div>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                <Row>
                {/*<Col lg="7" >*/}
                {/*  <Input*/}
                {/*      readOnly*/}
                {/*      style={{ cursor: "default" }}*/}
                {/*      placeholder={*/}
                {/*        with_self ? "加入自我查重" : "只与库查重"*/}
                {/*      }*/}
                {/*      onChange={this.onChange.bind(this, "with_self")}*/}
                {/*      prepend={*/}
                {/*        <div style={{ width: "100px", color: "#172b4d" }}>*/}
                {/*          是否加入自我查重*/}
                {/*        </div>*/}
                {/*      }*/}
                {/*  />*/}
                {/*</Col>*/}
                  <Col lg="3" >
                    <div>
                      包内文件是否需要互查:
                    </div>
                  </Col>
                  <Col lg="4" >
                    <Radio.Group onChange={this.onChange.bind(this, "with_self")} value={this.state.with_self}>
                      <Radio.Button value={false} checked={this.state.with_self === false}>否</Radio.Button>
                      <Radio.Button value={true} checked={this.state.with_self === true}>是</Radio.Button>
                    </Radio.Group>
                  </Col>
                  <Col lg="5" >
                    <div>
                      说明：若选择"是"，所提交压缩包内文件将会互相查重比对
                    </div>
                  </Col>
                </Row>
                </Form.Item>

                <Form.Item style={{ display: needCheck ? (private_lib ? "none":"block"): "none" }}>
                  <Row>
                    <Col lg="12">
                      <Search_lib
                        title="搜索库标签"
                        handleUpdateLibTag={this.handleUpdateLibTag}
                      />
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item style={{ display: needCheck ? (private_lib ? "block":"none") : "none" }}>
                  <Row>
                    <Col lg="12">
                      <Search_lib_private
                          title="搜索库标签"
                          handleUpdateLibTag={this.handleUpdateLibTagPrivate}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </div>
            </Collapse.Item>
          </Collapse>
          <br/>

          <BootButton
            color="primary"
            size="lg"
            block
            onClick={this.handleSubmit(true)}
            disabled={
              file.uid
                  ? jobTitle
                    ? submited
                    : true
                : true
            }
          >
            {file.uid
                ? jobTitle
                    ? submited
                        ? "查重任务已经提交"
                        : "文档提交并启动查重任务"
                    : "尚未填写查重任务标题"
                : "尚未添加待查重文档"
            }
          </BootButton>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreateJobForm);
