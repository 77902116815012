module.exports = {
  title: "cgplag",

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {string}
   * @description Request base url
   */
  baseUrl: `${process.env.REACT_APP_BASE_URL}`,
};
