/*
 * @Author: Charles Zhang
 * @Date: 2021-03-13 15:38:30
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-31 21:36:45
 */

import React, { Component } from "react";
import cookie from "react-cookies";
import PubSub from "pubsub-js";

import { Row, Col } from "reactstrap";

import { Card, Table, MessageBox } from "element-react";

import getDocumentBody from "api/getDocumentBody";
import ssoRefresh from "../../api/ssoRefresh";

export default class CheckResultCompare extends Component {
  cache = {};

  state = {
    columns: [
      {
        type: "expand",
        expandPannel: (data) => {
                const blockType = data.block_type;
                const blockNO = data.block_no;
                const docID = data.doc_id;

                if (blockType === "image") {
            if (docID in this.cache) {
                if(this.cache[docID] === "loading") return "图片加载中...";
              const image = this.cache[docID][blockNO - 1].content;
                const image_l = this.state.row.content;
              return (
                  <div style={{ cursor: "pointer" }}>
                    <img
                        alt={"【！！！ 图片未正确显示 ！！！】"}
                        src={image}
                        height={100}
                        onClick={this.onClick(image, image_l)}
                    />
                  </div>
              );
            } else {
              this.cache[docID] = "loading";
              getDocumentBody(cookie.load("ACCESS_TOKEN"), docID)
                  .then((response) => {
                      try{
                          if(response.data.data.message === "logout") {
                              ssoRefresh();
                          }
                      }
                      catch(e){
                          console.log(e);
                      }
                    this.cache[docID] = response.data.data.body;
                    this.forceUpdate();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              return "图片加载中...";
            }
          } else if (blockType === "code") {
                  let title_list = data.title.split(".");
                  if(title_list[title_list.length - 1] === "cgcode") {
                      // if ( && row)
                      // for(let key in stateObj.cache){
                      //     console.log(stateObj.cache[key][row.block_no - 1]);
                      //     break;
                      // }
                      // console.log(row.block_no);
                      // this.state.row.content
                      let out =[];
                      out.push(
                          <pre className="font-weight mb-0" style={{ color: "gray" }}>
                {"注：颜色标注只是方便对比查看，实际的相似度计算算法与此无关。\n\n"}
                </pre>)
                //       out.push(<pre className="mb-0" style={{ color: "black" }}>
                // {data.highlight.split("\n")[0]}
                // </pre>
                //           <pre className="mb-0" style={{ color: "tomato" }}>
                // {data.highlight.replace(data.highlight.split("\n")[0] + "\n", "")}
                // </pre>);
                //       out.push(
                //           <h6 className="font-weight-bold mb-1">
                // {data.highlight.split("\n")[0]}
                //           </h6>
                //       );
                      let hl = data.highlight.replace(data.highlight.split("\n")[0] + "\n", "").split("\n");
                      for(let line in hl){
                          let origin_judge = hl[line].replace(/^\s*|\s*$/g,"");
                          let judge0 = origin_judge.split("//")[0];
                          let judge = judge0.split("#")[0];
                          if((judge && this.state.row.content.indexOf(judge) !== -1)||(judge0 && this.state.row.content.indexOf(judge0) !== -1)||("//#".indexOf(origin_judge) === -1 && this.state.row.content.indexOf(origin_judge) !== -1)){
                              out.push(
                                  <pre className="font-weight mb-0" style={{ color: "red" }}>
                {hl[line]+"\n"}
                </pre>);
                          }
                          else if(this.similar_list(hl[line], this.state.row.content.split("\n")) > 0.7)
                          {
                              out.push(
                                  <pre className="font-weight mb-0" style={{ color: "orange" }}>
                {hl[line]+"\n"}
                </pre>);
                          }
                          else{
                              out.push(
                                  <pre className="font-weight mb-0" style={{ color: "black" }}>
                {hl[line]+"\n"}
                </pre>);
                          }
                      }
                      return (<div>{out}</div>);
                  }
                  else {
                    return (
                        <pre className="font-weight mb-0" style={{ color: "#005bd9" }}>
                {data.highlight}
              </pre>
                    );
                  }
          } else {
            return (
                <div dangerouslySetInnerHTML={{__html: data.highlight}}/>
            );
        }
        }
      },
      {
        label: "相似度",
        prop: "similarity",
        render: (data) => {
          return (Number(data.similarity) * 100).toFixed(0) + "%";
        },
        width: 100,
      },
      {
        label: "相似源",
        prop: "title",
        // width: 160,
      },
        {
        label: "备注",
        prop: "codeLocate",
        render: (data) => {
            return data.title.split(".")[data.title.split(".").length - 1] === "cgcode"?"代码定位：" + data.highlight.split("\n")[0]:"";
        },
      },
      // {
      //   label: "相似内容",
      //   prop: "highlight",
      //   render: (data) => {
      //     const blockType = data.block_type;
      //     const blockNO = data.block_no;
      //     const docID = data.doc_id;
      //
      //     if (blockType === "image") {
      //       if (docID in this.cache) {
      //         const image = this.cache[docID][blockNO - 1].content;
      //         return (
      //           <div style={{ cursor: "pointer" }}>
      //             <img
      //               alt={"【！！！ 图片未正确显示 ！！！】"}
      //               src={image}
      //               height={100}
      //               onClick={this.onClick(image)}
      //             />
      //           </div>
      //         );
      //       } else {
      //         const token = cookie.load("token");
      //
      //         getDocumentBody(token, docID)
      //           .then((response) => {
      //             this.cache[docID] = response.data.data.body;
      //             this.forceUpdate();
      //           })
      //           .catch((err) => {
      //             console.log(err);
      //           });
      //         return "图片加载中...";
      //       }
      //     } else if (blockType === "code") {
      //       return (
      //         <pre className="mb-0" style={{ color: "black" }}>
      //           {data.highlight}
      //         </pre>
      //       );
      //     } else {
      //       return (
      //         <div dangerouslySetInnerHTML={{ __html: data.highlight }}></div>
      //       );
      //     }
      //   },
      // },
    ],
    row: "",
    similar: [],
  };

 similar_list = (s, t_list) => {
     let max = 0;
     for(let i in t_list){
         const cur = this.similar(s, t_list[i]);
         if(cur > max) max =  cur;
     }
     return max;

 }
  similar = (s, t, f)  => {
    if (!s || !t) {
        return 0;
    }
      let l = s.length > t.length ? s.length : t.length;
      let n = s.length;
      let m = t.length;
      let d = [];
      f = f || 3;
      let min = function (a, b, c) {
        return a < b ? (a < c ? a : c) : (b < c ? b : c);
    }
      let i, j, si, tj, cost;
      if (n === 0) return m;
      if (m === 0) return n;
      for (i = 0; i <= n; i++) {
        d[i] = [];
        d[i][0] = i;
      }
      for (j = 0; j <= m; j++) {
        d[0][j] = j;
    }
    for (i = 1; i <= n; i++) {
        si = s.charAt(i - 1);
        for (j = 1; j <= m; j++) {
            tj = t.charAt(j - 1);
            if (si === tj) {
                cost = 0;
            } else {
                cost = 1;
            }
            d[i][j] = min(d[i - 1][j] + 1, d[i][j - 1] + 1, d[i - 1][j - 1] + cost);
        }
    }
    let res = (1 - d[n][m] / l);
    return res.toFixed(f);
  }

  onClick = (image, image_l) => {
    return () => {
      MessageBox.alert(
        <div style={{ overflow: "auto" }}>
            <Row>
            <Col lg="6">
                <h5 style={{color:"black"}}>原图</h5>
                <Card>
          <img alt={"【！！！ 图片未正确显示 ！！！】"} src={image_l} />
                </Card>
            </Col>
            <Col lg="6">
                <h5 style={{color:"red"}}>相似图</h5>
                <Card>
          <img alt={"【！！！ 图片未正确显示 ！！！】"} src={image} />
                </Card>
            </Col>
            </Row>
        </div>,
        "图片预览",
        { showConfirmButton: false }
      );
    };
  };

  handleContentType = (row) => {
    if (row.block_type === "text") {
      return <pre className="mb-0">{row.content}</pre>;
    } else if (row.block_type === "code") {
      return (
        <pre className="mb-0" style={{ color: "tomato" }}>
          {row.content}
        </pre>
      );
    } else if (row.block_type === "image") {
      return (
        <div style={{ cursor: "pointer" }}>
          <img
            alt={"【！！！ 图片未正确显示 ！！！】"}
            src={row.content}
            height={100}
            onClick={this.onClick(row.content)}
          />
        </div>
      );
    } else {
      return "请在左侧选择一行";
    }
  };

  componentDidMount() {
    // 先取消订阅，避免重复触发的问题
    PubSub.unsubscribe("updateCompare");
    PubSub.subscribe("updateCompare", (_, stateObj) => {
      const row = stateObj.row;
      const similar = stateObj.similar;

      this.setState({ row, similar });

      this.cache = { ...this.cache, ...stateObj.cache };
    });
  }

  render() {
    const { columns, row, similar } = this.state;

    return (
      <>
        <Card
          className="box-card"
          bodyStyle={{
            height: window.innerHeight - 330,
            overflow: "auto",
          }}
          header={
            <div className="clearfix">
              <span style={{ lineHeight: "36px" }} className="h6 text-muted">
                相似源列表
              </span>
              <span style={{ float: "right" }}>
                {/*<Button type="primary" onClick={() => {}}>*/}
                {/*  {"重新获取"}*/}
                {/*</Button>*/}
              </span>
            </div>
          }
        >
          {/*<Card*/}
          {/*  className="box-card"*/}
          {/*  style={{ marginBottom: "20px" }}*/}
          {/*  header={*/}
          {/*    <div className="clearfix my--1">*/}
          {/*      <span style={{ lineHeight: "15px" }} className="h6 text-muted">*/}
          {/*        原文*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*  }*/}
          {/*>*/}
          {/*  {this.handleContentType(row)}*/}
          {/*</Card>*/}
          <Card
            className="box-card"
            bodyStyle={{
              padding: 0,
            }}
            header={
              <div className="clearfix my--1">
                <span style={{ lineHeight: "15px" }} className="h6 text-muted">
                  相似源（鼠标移动至左侧原文或标题处查看对应相似情况）
                </span>
              </div>
            }
          >
            <Table
              style={{ width: "100%", overflowX: "hidden" }}
              columns={columns}
              data={similar}
              showHeader={true}
              stripe={true}
              border={true}
              defaultExpandAll={row?row.block_type !== "code":true}
            />
          </Card>
        </Card>
      </>
    );
  }
}
