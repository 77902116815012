import request from "./requestLongTimeOut.js";

export default function getDocumentBody(access_token, doc_id) {
  return request({
    url: "/document",
    method: "get",
    headers: {
      access_token,
    },
    params: {
      doc_id,
    },
  });
}
