/*
 * @Author: Charles Zhang
 * @Date: 2021-02-26 12:13:45
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-13 11:07:29
 */

import React, { Component } from "react";

import { Table, Loading, Dialog, Button } from "element-react";

import ResultBoard from "./ResultBoard";

import cookie from "react-cookies";
import PubSub from "pubsub-js";

import getCheckProgress from "api/getCheckProgress";
import deleteResult from "api/deleteResult";
import ssoRefresh from "../../api/ssoRefresh";

export default class ResultTable extends Component {
  state = {
    data: [],
    isLoading: true,
    emptyText: "目前没有查重任务",
    dialogVisible: false,
    resultBoardVisible: false,
  };

  nowChooseRowData = {};

  handleCellMouseEnter = (row) => {
    this.nowChooseRowData = row;
  };

  handleResultShow = () => {
    PubSub.publish("hideNavbar");
    PubSub.publish("fetchCheckResult", { id: this.nowChooseRowData.id });
    this.setState({ resultBoardVisible: true });
  };

  closeResultBoard = () => {
    PubSub.publish("showNavbar");
    this.setState({ resultBoardVisible: false });
  };

  handleResultDelete = () => {
    this.setState({ dialogVisible: true });
  };

  doResultDelete = () => {
    deleteResult(cookie.load("ACCESS_TOKEN"), this.nowChooseRowData.id)
      .then((response) => {
        try{
          if(response.data.data.message === "logout") {
            ssoRefresh();
          }
        }
        catch(e){
          console.log(e);
        }
        this.setState({ isLoading: true });
        setTimeout(this.updateCheckData, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateCheckData = () => {

    getCheckProgress(cookie.load("ACCESS_TOKEN"), "_all")
      .then((response) => {
        // 读取到数据后停止加载页显示
        try{
          if(response.data.data.message === "logout") {
            ssoRefresh();
          }
        }
        catch(e){
          console.log(e);
        }
        this.setState({ isLoading: false });

        const check_jobs = response.data.data.result;

        const data = [];

        for (const key in check_jobs) {
          const id = key;
          const name = check_jobs[key].job_name;
          const craeteTime = check_jobs[key].start_time;
          const endTime = check_jobs[key].end_time;
          const status = check_jobs[key].status;
          const total = check_jobs[key].total_docs;

          const startTimestamp = new Date(
            craeteTime.substring(0, 19).replace(/-/g, "/")
          ).getTime();

          const endTimestamp = new Date(
            endTime.substring(0, 19).replace(/-/g, "/")
          ).getTime();

          const totalTime = (endTimestamp - startTimestamp) / 1000 + "秒";
          if(totalTime == "NaN秒") continue;

          data.unshift({
            id,
            name,
            status,
            total,
            craeteTime,
            endTime,
            totalTime,
          });
        }

        data.sort((a, b) => a.craeteTime - b.craeteTime);

        for (let i in data) {
          // 由于 element-react 表格的index序号显示有问题，故自己创建index
          data[i].index = Number(i) + 1;

          // 由于状态提升至父组件，故要加入两个操作方法
          data[i].operation = {};
          data[i].operation.show = this.handleResultShow;
          data[i].operation.delete = this.handleResultDelete;
        }

        this.setState({ data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.updateCheckData();

    // 先取消订阅，避免重复触发的问题
    PubSub.unsubscribe("updateResult");
    PubSub.subscribe("updateResult", () => {
      this.updateCheckData();
    });
  }

  render() {
    const {
      data,
      isLoading,
      emptyText,
      dialogVisible,
      resultBoardVisible,
    } = this.state;

    const { columns } = this.props;

    return (
      <>
        <Loading text="正在尝试拉取查重报告列表" loading={isLoading}>
          <Table
            style={{ width: "100%" }}
            columns={columns}
            data={data}
            border={true}
            stripe={true}
            height={548}
            highlightCurrentRow={true}
            emptyText={isLoading ? "" : emptyText}
            onCellMouseEnter={this.handleCellMouseEnter}
          />
        </Loading>
        <Dialog
          title="确认删除"
          size="tiny"
          visible={dialogVisible}
          onCancel={() => this.setState({ dialogVisible: false })}
          lockScroll={false}
        >
          <Dialog.Body>
            <div className="mb-2">是否确认删除文档库？</div>
            <ul>
              <li>任务ＩＤ：{this.nowChooseRowData.id}</li>
              <li>任务名称：{this.nowChooseRowData.name}</li>
              <li>创建时间：{this.nowChooseRowData.craeteTime}</li>
              <li>文档数量：{this.nowChooseRowData.total}</li>
              <li>查重用时：{this.nowChooseRowData.totalTime}</li>
            </ul>
          </Dialog.Body>
          <Dialog.Footer className="dialog-footer">
            <Button onClick={() => this.setState({ dialogVisible: false })}>
              取消
            </Button>
            <Button
              type="danger"
              onClick={() => {
                this.doResultDelete();
                this.setState({ dialogVisible: false });
              }}
            >
              确定
            </Button>
          </Dialog.Footer>
        </Dialog>
        <Dialog
          title={"查重结果：" + this.nowChooseRowData.name}
          size="full"
          visible={resultBoardVisible}
          onCancel={this.closeResultBoard}
          closeOnPressEscape={true}
          lockScroll={true}
        >
          <Dialog.Body>
            <ResultBoard data={this.nowChooseRowData} secret_change={true}/>
          </Dialog.Body>
        </Dialog>
      </>
    );
  }
}
