/*
 * @Author: Charles Zhang
 * @Date: 2021-02-20 16:29:54
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-29 15:21:03
 */

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));
