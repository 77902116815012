import JSEncrypt from "jsencrypt";

// 公匙加密
export default function encrypt(text) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUB_KEY);
    return encrypt.encrypt(text);
}

const PUB_KEY = `
-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAnsC4P3YcyWSXuRFHJUiZ
QFjWKfE9DTs1FGVQXQh1yV/v+9fowCF0n3T1v7YipY9hpffqSaykx1N2QJFgmbOl
+f2OF93uJ3pW7Qv78D9p1iJRRf4zizljUbHE4deXTgxELB/Dwb2O65SDAja8N0Cb
sJx7y7AqIg/hnx97cVBVM1JMVXQZQNBWltwjc4luDG2TDZ1CKnvToe+w1NW1ctpZ
8iBzcMljhgb/R4H4ihaQAYaCevQzndeZ/cd1ocE2K+ca/BIeFdCTCFcQOst8jlCV
nNQbKbbl4toTkt/+t+PylpBN9yYF+79sjmsROcbyl/kBcDEhKfgEkRSwIAiUBAmD
u7O2lL4GWdmJ/AwjdjPVWd91ioMZI7AZ5NRIYs8sJQJBEE9rciKQuxSiVCgJh807
+aCMFog1jjN1pWhqxmhoe7rjOc/83aSQlIAgkNL+edIZtQM2OjrvjNPi43R96EO+
vx3JYA8ebRQEPzU6igFs6jihyWs59WN+F1ljU3N3VWpDyst6IDk39EszW5fTZzgn
V3HUkl80YNGPFMBEAo/vZ5ivlDRtEdD5Pd9JwARJlj1hMEtK8owXk1j+6BXAvYrN
+OVlxY5lH/KMebhksQBN4S3dVITOEhzJpaOwVcOof7NFXyfkEL39ZbTMoiAMxLnX
NJ7/MgdF/71R/ple18eZOOkCAwEAAQ==
-----END PUBLIC KEY-----
`;

