/*
 * @Author: Charles Zhang
 * @Date: 2021-03-10 14:18:22
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-12 12:20:31
 */

import React, { Component } from "react";

import { Card } from "element-react";

export default class MiniCard extends Component {
  render() {
    const { title, content } = this.props;

    return (
      <>
        <Card
          className="box-card"
          style={{ height: "88px", overflow: "auto" }}
        >
          <div className="mt--2 mb--4 mx--1 h6 text-muted">{title}</div>
          <hr />
          <div className="mt--4 mb--2 mx--1 font-weight-bold">{content}</div>
        </Card>
      </>
    );
  }
}
