import request from "./requestLongTimeOut.js";

export default function createBatch(
  access_token,
  files,
  file_format,
  batch_name,
  description,
  job_name,
  search_range,
  file_type,
  suffix_screen,
  with_self
) {
  const formData = new FormData();

  if (!search_range) {
    search_range = "";
  }

  formData.append("files", files);
  formData.append("file_format", file_format);
  formData.append("file_type", file_type);
  formData.append("batch_name", batch_name);
  formData.append("description", description);
  formData.append("job_name", job_name);
  formData.append("search_range", search_range);
  formData.append("suffix_screen", suffix_screen);
  formData.append("with_self", with_self); // 判断是否同时跟自我查重

  return request({
    url: "/joint",
    method: "post",
    headers: {
      access_token,
    },
    data: formData,
  });
}
