/*
 * @Author: Charles Zhang
 * @Date: 2021-02-20 18:31:32
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-02-22 14:53:26
 */

import React from "react";
import { Link } from "react-router-dom";
import { NavLink as StrapLink } from "reactstrap";

import cookie from "react-cookies";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

export default class Hero extends React.Component {
  render() {
    return (
      <div className="position-relative">
        {/* Hero for FREE version */}
        <section className="section section-hero section-shaped">
          {/* Background circles */}
          <div className="shape shape-style-1 shape-default">
            <span className="span-150" />
            <span className="span-50" />
            <span className="span-50" />
            <span className="span-75" />
            <span className="span-100" />
            <span className="span-75" />
            <span className="span-50" />
            <span className="span-100" />
            <span className="span-50" />
            <span className="span-100" />
          </div>
          <Container className="shape-container d-flex align-items-center py-lg">
            <div className="col px-0">
              <Row className="align-items-center justify-content-center">
                <Col className="text-center" lg="12">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/brand/cgplag-react-white.png")}
                    style={{ width: "250px" }}
                  />
                  <p className="lead text-white">
                    <span>一个基于 ElasticSearch 的高效文档查重/代码查重系统！</span>
                    <br />
                    <span>
                      图文查重 + 代码查重 + 校内互查 + 自建库查重 + 查重结果聚类分析
                    </span>
                  </p>
                  <div className="btn-wrapper mt-5">
                    <Link to={cookie.load("isLogin") === "true"?"/check":"/"}>
                      <Button
                        className="btn-icon mb-3 mb-sm-0 mr-4"
                        color="success"
                        size="lg"
                        onClick={() => {
                          if(cookie.load("isLogin") !== "true"){
                            alert("请先点击右上角按钮登录!");
                          }
                        }}
                      >
                        <span className="btn-inner--icon align-text-bottom mr-0">
                          <i className="fa fa-rocket" />
                        </span>
                        <span
                          className="btn-inner--text"
                          style={{ fontSize: "1.2em" }}
                        >
                          提交查重文档
                        </span>
                      </Button>
                    </Link>
                    <Link to={cookie.load("isLogin") === "true"?"/check_code":"/"}>
                      <Button
                          className="btn-icon mb-3 mb-sm-0 mr-4"
                          color="default"
                          size="lg"
                          onClick={() => {
                            if(cookie.load("isLogin") !== "true"){
                              alert("请先点击右上角按钮登录!");
                            }
                          }}
                      >
                        <span className="btn-inner--icon align-text-bottom mr-0">
                          <i className="fa fa-flash" />
                        </span>
                        <span
                            className="btn-inner--text"
                            style={{ fontSize: "1.2em" }}
                        >
                          提交查重代码包
                        </span>
                      </Button>
                    </Link>
                    <Link to={cookie.load("isLogin") === "true"?"/result":"/"}>
                      <Button
                        className="btn-icon mb-3 mb-sm-0 mr-4"
                        color="secondary"
                        size="lg"
                        target="_blank"
                        onClick={() => {
                          if(cookie.load("isLogin") !== "true"){
                            alert("请先点击右上角按钮登录!");
                          }
                        }}
                      >
                        <span className="btn-inner--icon align-text-bottom mr-0">
                          <i className="fa fa-archive" />
                        </span>
                        <span
                          className="btn-inner--text"
                          style={{ fontSize: "1.2em" }}
                        >
                          管理查重报告
                        </span>
                      </Button>
                    </Link>
                    <Link to={cookie.load("isLogin") === "true"?"/update_lib":"/"}>
                      <Button
                          className="btn-icon mb-3 mb-sm-0 mr-4"
                          color="info"
                          size="lg"
                          target="_blank"
                          onClick={() => {
                            if(cookie.load("isLogin") !== "true"){
                              alert("请先点击右上角按钮登录!");
                            }
                          }}
                      >
                        <span className="btn-inner--icon align-text-bottom mr-0">
                          <i className="fa fa-database" />
                        </span>
                        <span
                            className="btn-inner--text"
                            style={{ fontSize: "1.2em" }}
                        >
                          文档/代码包入库
                        </span>
                      </Button>
                    </Link>
                  </div>
                  <div className="mt-5">

                    {/*<small className="text-white font-weight-bold mb-0 mr-0">*/}
                    {/*  *proudly coded by*/}
                    {/*</small>*/}
                    {/*<StrapLink*/}
                    {/*  href="https://github.com/PhenomingZ"*/}
                    {/*  target="_blank"*/}
                    {/*  style={{ display: "inline" }}*/}
                    {/*>*/}
                    {/*  <img*/}
                    {/*    alt="..."*/}
                    {/*    className="ml-1"*/}
                    {/*    style={{ height: "28px" }}*/}
                    {/*    src={require("assets/img/brand/charles-zhang-slim.png")}*/}
                    {/*  />*/}
                    {/*</StrapLink>*/}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        <Container style={{ display: "block"}}>

          <Row className=" row-grid align-items-center mb-5" style={{ height: "10px" }}/>

          <Row className=" row-grid align-items-center mb-5">
            <Col lg="6">
              <h3 className=" text-primary font-weight-light mb-2">
                文档特征元素自动提取、存储和比较
              </h3>
              <br/>
              <h5 className=" mb-0 font-weight-light">
                自动识别文档中的特征元素，针对文档中常见的<strong>文本元素</strong>、<strong>图片元素</strong>和<strong>代码元素</strong>分别给出了对应的特征提取、存储和比较方法。
              </h5>
            </Col>
            <Col className="text-lg-center btn-wrapper" lg="6">
              <img src={require("assets/img/feature/1.png")} alt="文档特征元素自动提取、存储和比较" style={{ width: "500px" }}/>
            </Col>
          </Row>

          <Row className=" row-grid align-items-center mb-5" style={{ height: "10px" }} />

          <Row className=" row-grid align-items-center mb-5">
            <Col className="text-lg-center btn-wrapper" lg="6">
              <img src={require("assets/img/feature/2.png")} alt="基于ElasticSearch的特征索引进行查重" style={{ width: "500px" }}/>
            </Col>
            <Col lg="6">
              <h3 className=" text-primary font-weight-light mb-2">
                基于ElasticSearch的特征索引进行查重
              </h3>
              <br/>
              <h5 className=" mb-0 font-weight-light">
                利用了ElasticSearch倒排索引的高效检索能力，查重耗时随着文档数量的增多呈线性增加趋势，传统查重工具呈指数上升趋势。
              </h5>
            </Col>
          </Row>

          <Row className=" row-grid align-items-center mb-5" style={{ height: "10px" }}/>

          <Row className=" row-grid align-items-center mb-5">
            <Col lg="6">
              <h3 className=" text-primary font-weight-light mb-2">
                代码包查重支持筛选语言
              </h3>
              <br/>
              <h5 className=" mb-0 font-weight-light">
                可手动筛选需要参与查重的代码语言，避开非必要的部分，提高效率的同时也提高了查重的针对性。
              </h5>
            </Col>
            <Col className="text-lg-center btn-wrapper" lg="6">
              <img src={require("assets/img/feature/3.png")} alt="代码包查重支持筛选语言" style={{ width: "500px" }}/>
            </Col>
          </Row>

          <Row className=" row-grid align-items-center mb-5" style={{ height: "10px" }}/>

          <Row className=" row-grid align-items-center mb-5">
            <Col className="text-lg-center btn-wrapper" lg="6">
              <img src={require("assets/img/feature/4.jpg")} alt="查重报告聚类展示，轻松发现聚集抄袭现象" style={{ width: "500px" }}/>
            </Col>
            <Col lg="6">
              <h3 className=" text-primary font-weight-light mb-2">
                查重报告聚类展示，轻松发现聚集抄袭现象
              </h3>
              <br/>
              <h5 className=" mb-0 font-weight-light">
                查重结果中不仅包含了对相似情况的统计分析，还指出了相似源、具体的相似部分以及相似度。代码元素和图片元素有不同的展示方式，查重结果更直观。
              </h5>
            </Col>
          </Row>

          <Row className=" row-grid align-items-center mb-5" style={{ height: "10px" }}/>

          <Row className=" row-grid align-items-center mb-5">
            <Col lg="12">
              <h3 className=" text-primary font-weight-light mb-2">
                入库归档，支持进行库内查重
              </h3>
              <br/>
              <h5 className=" mb-0 font-weight-light">
                可将文档/代码包入库，查重时可选择按库查重。入库设置公有/私有库，支持添加多个库标签用于标识。查重时支持选择公有/私有库，支持设置多个库标签互查，自由度高！
              </h5>
            </Col>
          </Row>

          <Row className=" row-grid align-items-center mb-5">
            <Col className="text-lg-center btn-wrapper" lg="12">
              <img src={require("assets/img/feature/5.png")} alt="入库归档，支持进行库内查重" style={{ width: "1000px" }}/>
            </Col>
          </Row>

          <Row className=" row-grid align-items-center mb-5" style={{ height: "10px" }}/>

          <Row className=" row-grid align-items-center mb-5">
            <Col lg="12">
              <h3 className=" text-primary font-weight-light mb-2">
                支持批量提交/入库
              </h3>
              <br/>
              <h5 className=" mb-0 font-weight-light">
                支持文件批量压缩打包上传，不做重复劳动。支持对rar、zip、tgz类型文件的上传解析，效率加倍！
              </h5>
            </Col>
          </Row>

          <Row className=" row-grid align-items-center mb-5" style={{ height: "10px" }}/>
          {/*<Col className="text-lg-center btn-wrapper" lg="6">*/}
          {/*<Button*/}
          {/*  className="btn-icon-only rounded-circle"*/}
          {/*  color="twitter"*/}
          {/*  href="https://twitter.com/creativetim"*/}
          {/*  id="tooltip475038074"*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  <span className="btn-inner--icon">*/}
          {/*    <i className="fa fa-twitter" />*/}
          {/*  </span>*/}
          {/*</Button>*/}
          {/*<UncontrolledTooltip delay={0} target="tooltip475038074">*/}
          {/*  Follow us*/}
          {/*</UncontrolledTooltip>*/}
          {/*<Button*/}
          {/*  className="btn-icon-only rounded-circle ml-1"*/}
          {/*  color="facebook"*/}
          {/*  href="https://www.facebook.com/creativetim"*/}
          {/*  id="tooltip837440414"*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  <span className="btn-inner--icon">*/}
          {/*    <i className="fa fa-facebook-square" />*/}
          {/*  </span>*/}
          {/*</Button>*/}
          {/*<UncontrolledTooltip delay={0} target="tooltip837440414">*/}
          {/*  Like us*/}
          {/*</UncontrolledTooltip>*/}
          {/*<Button*/}
          {/*  className="btn-icon-only rounded-circle ml-1"*/}
          {/*  color="dribbble"*/}
          {/*  href="https://dribbble.com/creativetim"*/}
          {/*  id="tooltip829810202"*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  <span className="btn-inner--icon">*/}
          {/*    <i className="fa fa-dribbble" />*/}
          {/*  </span>*/}
          {/*</Button>*/}
          {/*<UncontrolledTooltip delay={0} target="tooltip829810202">*/}
          {/*  Follow us*/}
          {/*</UncontrolledTooltip>*/}
          {/*<Button*/}
          {/*  className="btn-icon-only rounded-circle ml-1"*/}
          {/*  color="github"*/}
          {/*  href="https://github.com/creativetimofficial"*/}
          {/*  id="tooltip495507257"*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  <span className="btn-inner--icon">*/}
          {/*    <i className="fa fa-github" />*/}
          {/*  </span>*/}
          {/*</Button>*/}
          {/*<UncontrolledTooltip delay={0} target="tooltip495507257">*/}
          {/*  Star on Github*/}
          {/*</UncontrolledTooltip>*/}
          {/*</Col>*/}
        </Container>
      </div>
    );
  }
}
