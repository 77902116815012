/*
 * @Author: Charles Zhang
 * @Date: 2021-02-26 12:48:46
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-07 14:42:26
 */

import React, { Component } from "react";

import { Table, Progress, Loading } from "element-react";

import cookie from "react-cookies";
import PubSub from "pubsub-js";

// custom javascript
import getBatchProgress from "api/getBatchProgress";
import ssoRefresh from "../../api/ssoRefresh";

export default class LoadTable extends Component {
  state = {
    columns: [
      {
        label: "批次名称",
        prop: "name",
      },
      {
        label: "当前状态",
        prop: "status",
        width: 100,
      },
      {
        label: "提交进度",
        prop: "progress",
        width: 100,
        render: (data) => {
          return (
            <Progress
              style={{ marginRight: "-20px", marginLeft: "-10px" }}
              type="line"
              percentage={data.progress}
            />
          );
        },
      },
    ],
    data: [],
    isLoading: true,
    emptyText: "没有正在提交的文档",
  };

  dataPreLength = -1;
  retryTimesSetter = 5;

  updateBatchData = () => {
    if (this.retryTimes <= 0) {
      // 超过重试次数后停止加载页显示
      this.setState({ isLoading: false });
      return false;
    }

    getBatchProgress(cookie.load("ACCESS_TOKEN"), "_all")
      .then((response) => {
        try{
          if(response.data.data.message === "logout") {
            ssoRefresh();
          }
        }
        catch(e){
          console.log(e);
        }

        const batchs = response.data.data.result;

        const data = [];

        let flag = true;
        for (const key in batchs) {
          const name = batchs[key].data.batch_name;
          const craeteTime = batchs[key].data.create_time;
          const status = batchs[key].status;

          if (status === "finished") {
            continue;
          }

          // 读取到数据后停止加载页显示
          this.setState({ isLoading: false });

          const progress =
            Math.floor(Number(batchs[key].progress) / 2) +
            (status === "saving" ? 50 : 0);

          data.unshift({ name, status, progress, craeteTime });
          flag = false;
          this.retryTimes = this.retryTimesSetter;
        }

        data.sort((a, b) => a.craeteTime - b.craeteTime);
        this.setState({ data });

        if (data.length < this.dataPreLength) {
          // 向 BatchTable 发布消息，通知更新入库文档列表
          PubSub.publish("updateBatch", { isLoading: false });

          // 向 CheckTable 发布消息，通知更新查重任务列表
          PubSub.publish("updateCheck", { isLoading: false });
        }

        this.dataPreLength = data.length;

        if (flag) {
          this.retryTimes -= 1;
        }
        setTimeout(this.updateBatchData, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.retryTimes = 1;
    this.updateBatchData();
  }

  render() {
    const { columns, data, isLoading, emptyText } = this.state;

    return (
      <Loading text="正在尝试拉取列表" loading={isLoading}>
        <Table
          style={{ width: "100%" }}
          columns={columns}
          data={data}
          border={true}
          stripe={true}
          height={148}
          emptyText={isLoading ? "" : emptyText}
        />
      </Loading>
    );
  }
}
