/*
 * @Author: Charles Zhang
 * @Date: 2021-02-22 14:17:42
 * @Last Modified by: Garywoner
 * @Last Modified time: 2021-04-26 14:54:00
 */

import React, { Component } from "react";

import { Button, Upload, Input, Dialog, Radio } from "element-react";

import { Row, Col } from "reactstrap";

import "./index.css";

const tmpFilename = "未选择文件（支持 zip、rar、tgz 打包上传）";
export default class ManualUpload extends Component {
  state = {
    filename: tmpFilename,
    allowUpload: false,
    showTips: false,
    fileFormat: "",
  };

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };
  onFormatChange = (key, value) => {
    this.onChange(key, value);
    const { handleFormat } = this.props;
    handleFormat(value);
  };

  submitUpload() {
    this.upload.submit();
  }

  handleBeforeUpload = (file) => {
    const { handleUpdateFile } = this.props;

    this.setState({ filename: file.name });

    handleUpdateFile(file);

    return this.state.allowUpload;
  };

  clearFileList = () => {
    this.setState({ filename: tmpFilename });
    this.upload.clearFiles();
    const { handleUpdateFile } = this.props;
    handleUpdateFile({});
  };

  render() {
    const { filename, showTips } = this.state;

    return (
      <div>
        <Row>
        <Dialog
            title="上传帮助"
            size="medium"
            visible={showTips}
            onCancel={() => this.setState({ showTips: false })}
            lockScroll={false}
        >
          <Dialog.Body>
            <ul>
              <Col lg="11">
              <h2>CG-Plag上传帮助</h2>
                <br/>
                <h6><strong>注1：若待查重文件为希冀平台导出的电子归档，提交前请勿对压缩包内文件作任何修改、删除，否则可能会导致查重任务中断或查重结果异常。</strong></h6>
                <h6><strong>注2：以下上传帮助只面向用户自行准备待查重压缩包的场景。</strong></h6>
              <h5>文件的上传分为以下三种情况：</h5>

                <ol><li><p>提交查重文档</p><p>若您当前正在进行该操作，那么您提交的查重文档应当满足以下几点要求：</p>
                  <ul><li><p>可以上传单个文档，但建议<strong>开启库查重</strong>，否则批次内仅有单个文档，没有查重效果。</p></li>
                    <li><p>支持上传<strong>zip、rar或tgz打包的压缩包</strong>，但要求<strong>压缩包一级目录下的每一个文件都作为一个文档</strong>。</p></li>
                      <Col className="text-lg-left btn-wrapper">
                        <img src={require("assets/img/upload/文档上传演示.png")} alt="文档上传演示" style={{border:"double black", width: "680px" }}/>
                      </Col>
                    <br/>
                    <li><p>所上传的<strong>文档类型仅支持docx、doc和pdf</strong>，其他类型可能会出错。</p></li></ul></li>
                <br/>
                  <li><p>提交查重代码包</p><p>若您当前正在进行该操作，请注意以下要求：</p>
                    <ul><li><p><strong>不可上传</strong>单个代码文件。</p></li>
                      <li><p>支持上传<strong>zip、rar或tgz打包的压缩包</strong>，但要求<strong>压缩包一级目录下的每一个文件（包括压缩包，将递归解析压缩包）和文件夹都作为一个代码文件（认定为一位学生的代码）</strong>。</p></li>
                      <Col className="text-lg-left btn-wrapper">
                        <img src={require("assets/img/upload/代码包上传演示.png")} alt="代码包上传演示" style={{border:"double black", width: "680px"}}/>
                      </Col>
                      <br/>
                      <li><p>可以并建议手动筛选识别的语言，因为自动筛选高频语言可能会影响查重效率和结果。</p></li></ul></li>
                <br/>
                <li><p>文档、代码包入库</p><p>若您当前正在进行该操作，那么我们有如下几点建议：</p><ul><li><p>入库请设置好标题，并<strong>务必选择库标签</strong>，这将是您的入库文件分类的标准。</p></li><li><p>入库时上传文件的要求也请参考前面两条。</p></li><li><p>上传入库的文件请确保无误，如上传了错误的文件请及时删除，让我们一起打造一个干净明朗的库环境！</p></li></ul></li></ol>
                <br/>
              <h5><p>若未按要求进行上传操作可能会在进度上显示Error字样。但您不必担心，一段时间后会自行消失。</p></h5>
              </Col>
            </ul>
          </Dialog.Body>
          <Dialog.Footer className="dialog-footer">
            <Button
                type="success"
                onClick={() => {
                  this.setState({ showTips: false });
                }}
            >
              确定
            </Button>
          </Dialog.Footer>
        </Dialog>
        <Col lg="8">
          <Input
            readOnly
            placeholder={filename}
            style={{ cursor: "default" }}
            prepend={
              <div
                className="text-left"
                style={{ width: "100px", color: "#172b4d" }}
              >
                上传待查文件
              </div>
            }
            append={
              <Button type="primary" onClick={this.clearFileList}>
                清除已选择文件
              </Button>
            }
          />
        </Col>
        <Col lg="2">
          <Upload
            className="upload-demo"
            ref={(c) => (this.upload = c)}
            action="//jsonplaceholder.typicode.com/posts/"
            beforeUpload={this.handleBeforeUpload}
            showFileList={false}
            limit={1}
          >
            <Button
              type="primary"
              icon="upload"
              onClick={this.clearFileList}
              style={{ width: "100%" }}
            >
              选择文件
            </Button>
          </Upload>
        </Col>
        <Col lg="2">
          <Button
              type="warning"
              icon="information"
              onClick={() => this.setState({ showTips: true })}
              style={{ width: "100%" }}
          >
            上传帮助
          </Button>
        </Col>
        </Row>
        <p/>
        {/*<Row>*/}
        {/*  <Col lg="7">*/}
        {/*    <Input*/}
        {/*        readOnly*/}
        {/*        placeholder={"希冀直接导出的文件未做任何修改请选“是”"}*/}
        {/*        style={{ cursor: "default"}}*/}
        {/*        prepend={*/}
        {/*          <div*/}
        {/*              className="text-left"*/}
        {/*              style={{ width: "100px", color: "#172b4d" }}*/}
        {/*          >*/}
        {/*            是否在希冀导出*/}
        {/*          </div>*/}
        {/*        }*/}
        {/*    />*/}
        {/*    /!*<h6 className="text-primary font-weight mb-4">*!/*/}
        {/*    /!*  所选文件格式识别*!/*/}
        {/*    /!*</h6>*!/*/}
        {/*  </Col>*/}
        {/*  <Col lg="5">*/}
        {/*    <Radio.Group onChange={this.onFormatChange.bind(this, "fileFormat")} value={this.state.fileFormat}>*/}
        {/*      <Radio.Button value={""} checked={this.state.fileFormat === ""}>否</Radio.Button>*/}
        {/*      <Radio.Button value={"cg"} checked={this.state.fileFormat === "cg"}>是</Radio.Button>*/}
        {/*    </Radio.Group>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </div>
    );
  }
}
