/*
 * @Author: Charles Zhang 
 * @Date: 2021-02-22 14:17:31 
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-02-26 02:21:43
 */


import React, { Component } from "react";

export default class Background extends Component {
  render() {
    return (
      <div>
        {/* Circles background */}
        <div className="shape shape-style-1 shape-default alpha-4">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
    );
  }
}
