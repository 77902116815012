/*
 * @Author: Charles Zhang
 * @Date: 2021-02-20 16:22:05
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-02-21 10:08:10
 */

import React from "react";

import Hero from "components/Hero";

export default class Home extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.main.scrollTop = 0;
  }
  render() {
    return (
      <div>
        <main ref={(c) => (this.main = c)}>
          <Hero />
        </main>
      </div>
    );
  }
}
