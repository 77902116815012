/*
 * @Author: Charles Zhang
 * @Date: 2021-02-22 14:36:31
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-02-26 02:26:01
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";

export default class Description extends Component {
  render() {
    // Description message
    const { title, subtitle, description } = this.props;

    // Button link config
    const { buttons } = this.props;

    return (
      <Container className="py-lg-md d-flex">
        <div className="col px-0">
          <Row>
            <Col lg="8">
              <h1 className="display-3 text-white">
                {title} <span>{subtitle}</span>
              </h1>
              <p className="lead text-white">{description}</p>
              <div className="btn-wrapper">
                {(buttons ? buttons : []).map((buttonObj) => (
                  <Link to={buttonObj.to} key={buttonObj.to}>
                    <Button
                      className="btn-icon mt--3 mb-3 mr-3"
                      color={buttonObj.color}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className={buttonObj.icon} />
                      </span>
                      <span className="btn-inner--text">{buttonObj.title}</span>
                    </Button>
                  </Link>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
