/*
 * @Author: Garywoner
 * @Date: 2021-04-26 15:14:00
 * @Last Modified by: Garywoner
 * @Last Modified time: 2021-04-26 15:14:00
 */

import React, { Component } from "react";
import cookie from "react-cookies";

import { Select as ElementSelect, Input, Card, Button, Form} from "element-react";

import { Row, Col } from "reactstrap";

import "./index.css";

import searchLibTag from "api/searchLibTag";
import ssoRefresh from "../../api/ssoRefresh";

export default class Search_lib extends Component {
  state = {
    key: "",
    candidateValue: [],
    selectedValue: [],
    moreVisible: false,
  };

  handleChange = (value) => {

    const {handleUpdateLibTag} = this.props;

    var pl = this.state.selectedValue.indexOf(value);
    if (pl === -1) {
        this.state.selectedValue.push(value);
    }

    let lib_tag = this.state.selectedValue.join("/");
    handleUpdateLibTag(lib_tag);
    this.setState({["key"]: this.state.key});
  };

  handleChangeDelete = (value) => {

      const {handleUpdateLibTag} = this.props;

      var pl = this.state.selectedValue.indexOf(value);
      if (pl !== -1) {
          this.state.selectedValue = this.state.selectedValue.filter(item => item !== value);
      }

      let lib_tag = this.state.selectedValue.join("/");
      handleUpdateLibTag(lib_tag);
      this.setState({["key"]: this.state.key});
  };

    onChange = (key, value) => {
        this.setState({ [key]: value });
    };

  handleSearchLib = () => {

      searchLibTag(cookie.load("ACCESS_TOKEN"), this.state.key, 30, "")
          .then((response) => {
              try{
                  if(response.data.data.message === "logout") {
                      ssoRefresh();
                  }
              }
              catch(e){
                  console.log(e);
              }
              this.state.candidateValue = [];
              let result = response.data.data.result;
              for(let tag in result){
                  this.state.candidateValue.push(result[tag]);
              }
              this.state.moreVisible = response.data.data.is_all === "unknown";
              this.setState({["key"]: this.state.key});
          })
          .catch((err) => {
              console.log(err);
          });
  };

  render() {
    const { title } = this.props;

    return (
      <div id="check-range-selecter">
        <Row>
          <Col lg="12" md="6" sm="2">
            <Form.Item prop="key">
            <Input
              id="check-range-title"
              value={this.state.key}
              style={{ cursor: "default" }}
              onChange={this.onChange.bind(this, "key")}
              placeholder="请输入关键字以搜索公有库标签"
              prepend={
                <div style={{ width: "100px", color: "#172b4d" }}>{title}</div>
              }
              append={
                  <Button type="success" onClick={this.handleSearchLib}>
                      搜索
                  </Button>
              }
            />
            </Form.Item>
          </Col>
        </Row>
          <h6 className="font-weight-bold mb-1">
              <br/>
              搜索的公有库标签结果（点击可选中）
          </h6>
          <Card>
              {
                  this.state.candidateValue.map(
                      (v, index) =>{ return <Button type="primary" size="medium" className="ml-0 mr-1 my-2" onClick={() => {
                          this.handleChange(v);
                      }}>{v}</Button> }
                  )
              }
              <h7 className="font-weight mb-1"
                  style={{ display: this.state.moreVisible ? "block" : "none" }}>
                  还有更多搜索结果，请增加关键字信息...
              </h7>
          </Card>
          <h6 className="font-weight-bold mb-1">
              <br/>
              选中的公有库标签（点击可删除）
          </h6>
          <Card>
              {
                  this.state.selectedValue.map(
                      (v, index) =>{ return <Button type="success" size="medium" className="ml-0 mr-1 my-2" onClick={() => {
                          this.handleChangeDelete(v);
                      }}>{v}</Button> }
                  )
              }
          </Card>
      </div>
    );
  }
}
