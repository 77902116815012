import axios from "axios";
import defaultSettings from "./settings.js";

import { Notification } from "element-react";


function Alert(str) {
  var msgw,msgh,bordercolor,titleheight,titlecolor;
  msgw=420;//提示窗口的宽度
  msgh=70;//提示窗口的高度
  titleheight=40 //提示窗口标题高度
  bordercolor="#E8E8E8";//提示窗口的边框颜色
  titlecolor="#E8E8E8";//提示窗口的标题颜色
  var sWidth,sHeight;
  //获取当前窗口尺寸
  sWidth = document.body.offsetWidth;
  sHeight = document.body.offsetHeight;
//    //背景div
  var bgObj=document.createElement("div");
  bgObj.setAttribute('id','alertbgDiv');
  bgObj.style.position="absolute";
  bgObj.style.top="0";
  bgObj.style.background="#E8E8E8";
  bgObj.style.filter="progid:DXImageTransform.Microsoft.Alpha(style=3,opacity=25,finishOpacity=75";
  bgObj.style.opacity="0.6";
  bgObj.style.left="0";
  bgObj.style.width = sWidth + "px";
  bgObj.style.height = sHeight + "px";
  bgObj.style.zIndex = "10000";
  document.body.appendChild(bgObj);
  //创建提示窗口的div
  var msgObj = document.createElement("div")
  msgObj.setAttribute("id","alertmsgDiv");
  msgObj.setAttribute("align","center");
  msgObj.style.background="white";
  msgObj.style.border="1px solid " + bordercolor;
  msgObj.style.position = "absolute";
  msgObj.style.left = "50%";
  msgObj.style.font="12px/1.6em Verdana, Geneva, Arial, Helvetica, sans-serif";
  //窗口距离左侧和顶端的距离
  msgObj.style.marginLeft = "-225px";
  //窗口被卷去的高+（屏幕可用工作区高/2）-150
  msgObj.style.top = document.body.scrollTop+(window.screen.availHeight/2)-150 +"px";
  msgObj.style.width = msgw + "px";
  msgObj.style.height = msgh + "px";
  msgObj.style.textAlign = "center";
  msgObj.style.lineHeight ="25px";
  msgObj.style.zIndex = "10001";
  document.body.appendChild(msgObj);
  //提示信息标题
  // var title=document.createElement("h4");
  // title.setAttribute("id","alertmsgTitle");
  // title.setAttribute("align","left");
  // title.style.margin="0";
  // title.style.padding="3px";
  // title.style.background = bordercolor;
  // title.style.filter="progid:DXImageTransform.Microsoft.Alpha(startX=20, startY=20, finishX=100, finishY=100,style=1,opacity=75,finishOpacity=100);";
  // title.style.opacity="0.75";
  // title.style.border="1px solid " + bordercolor;
  // title.style.height="18px";
  // title.style.font="12px Verdana, Geneva, Arial, Helvetica, sans-serif";
  // title.style.color="#E8E8E8";
  // title.innerHTML="";
  // document.getElementById("alertmsgDiv").appendChild(title);
  // 提示信息
  var txt = document.createElement("p");
  txt.setAttribute("id","msgTxt");
  txt.style.margin="18px 0";
  txt.innerHTML = str;
  document.getElementById("alertmsgDiv").appendChild(txt);
}
function closewin() {
  document.body.removeChild(document.getElementById("alertbgDiv"));
  // document.getElementById("alertmsgDiv").removeChild(document.getElementById("alertmsgTitle"));
  document.body.removeChild(document.getElementById("alertmsgDiv"));
}


// create an axios instance
const service = axios.create({
  baseURL: defaultSettings.baseUrl, // url = base url + request url
  timeout: 600000000, // request timeout,————1000分钟，基本不限时了
  validateStatus: (status) => {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
  onUploadProgress: (evt) => {
    // 对原生进度事件的处理
    const progress = parseInt((evt.loaded / evt.total) * 100);
    try{
      closewin();
    }
    catch (e) {
    }
    if(isNaN(progress)) {
      console.log(evt.loaded)
      console.log(evt.total)
      console.error("progress is NaN!");
      Alert("向服务器上传数据出现问题: progress is NaN!");
      setTimeout(function(){  closewin(); }, 5000);
      return;
    }
    Alert("正在上传数据中，进度："+ progress + "%");
    if(progress==100){
      closewin();
      Alert("上传完成");
      setTimeout(function(){  closewin(); }, 1000);
    }
  },
  onDownloadProgress: (evt) => {
    // 对原生进度事件的处理
    const progress = parseInt((evt.loaded / evt.total) * 100);
    try{
      closewin();
    }
    catch (e) {
    }
    if(isNaN(progress)) {
      console.log(evt.loaded)
      console.log(evt.total)
      console.error("progress is NaN!");
      Alert("从服务器下载数据出现问题: progress is NaN!");
      setTimeout(function(){  closewin(); }, 5000);
      return;
    }
    Alert("正在下载数据中，进度：" + progress + "%");
    if (progress == 100) {
      closewin();
    }
  },
});

service.interceptors.request.use(
  (config) => {
    // 在发送请求前做些什么
    return config;
  },
  (err) => {
    // 在请求错误的时候的逻辑处理
    Notification.error({
      showClose: true,
      message: "抱歉，系统发生了未知错误 (003)！",
    });
    return Promise.reject(err);
  }
);

service.interceptors.response.use(
  (res) => {
    // 在请求成功后的数据处理
    return res;
  },
  (err) => {
    // 在响应错误的时候的逻辑处理
    console.log(err);
    Notification.error({
      showClose: true,
      message: "抱歉，系统发生了未知错误 (004)！",
    });
    return Promise.reject(err);
  }
);

export default service;
