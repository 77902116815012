import React from "react";

import {Dialog, MessageBox} from "element-react";

import ResultBoard from "../Result/ResultBoard";
import getCheckProgress from "api/getCheckProgress";
import cookie from "react-cookies";
import PubSub from "pubsub-js";
import decrypt from "../../components/RSA/decrypt";

export default class Check_report extends React.Component {
    state = {
        secret_change: false,
    };

    nowChooseRowData = {};

    handleResultShow = () => {
        PubSub.publish("hideNavbar");
        PubSub.publish("fetchCheckResult", {id: this.nowChooseRowData.id});
    };
    handleResultDelete = () => {
        this.setState({dialogVisible: true});
    };

    urlArgs = (query) => {
        const args = {};
        if (!query) return args;
        const pairs = query.split("&");
        for (let i = 0; i < pairs.length; i++) {
            const pos = pairs[i].indexOf("=");
            if (pos === -1) {
                continue;
            }
            const name = pairs[i].substring(0, pos);
            args[name] = pairs[i].substring(pos + 1);
        }
        return args;
    };
    updateCheckData = () => {
        const search0 = this.urlArgs(this.props.location.search.substring(1));
        const search = this.urlArgs(decrypt(search0["s_token"]));
        const secret_change = search["secret_change"] === "true";
        this.setState({secret_change});

        const job_id = search["job_id"];
        const access_token = search["access_token"];
        // if(access_token !== cookie.load("ACCESS_TOKEN")){
        cookie.save("ACCESS_TOKEN", access_token, {path: "/"});
        // cookie.remove("loginKey", { path: "/" });
        // cookie.remove("organization", { path: "/" });
        // cookie.save("isLogin", false, { path: "/" });
        // }

        getCheckProgress(access_token, "_all")
            .then((response) => {

                const check_jobs = response.data.data.result;
                if (Object.keys(check_jobs).length === 0) {
                    MessageBox.msgbox({
                        title: '消息',
                        message: "加载失败！请检查该报告是否存在或token已过期。",
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    return;
                }
                for (const key in check_jobs) {

                    if (key !== job_id) continue;

                    const id = key;
                    const name = check_jobs[key].job_name;
                    const craeteTime = check_jobs[key].start_time;
                    const endTime = check_jobs[key].end_time;
                    const status = check_jobs[key].status;
                    const total = check_jobs[key].total_docs;

                    const startTimestamp = new Date(
                        craeteTime.substring(0, 19).replace(/-/g, "/")
                    ).getTime();

                    const endTimestamp = new Date(
                        endTime.substring(0, 19).replace(/-/g, "/")
                    ).getTime();

                    const totalTime = (endTimestamp - startTimestamp) / 1000 + "秒";
                    if (totalTime === "NaN秒") continue;
                    this.nowChooseRowData = {
                        id,
                        name,
                        status,
                        total,
                        craeteTime,
                        endTime,
                        totalTime,
                    };
                    // 由于 element-react 表格的index序号显示有问题，故自己创建index
                    this.nowChooseRowData.index = 1;

                    // 由于状态提升至父组件，故要加入两个操作方法
                    this.nowChooseRowData.operation = {};
                    this.nowChooseRowData.operation.show = this.handleResultShow;
                    this.nowChooseRowData.operation.delete = this.handleResultDelete;
                }
                if (Object.keys(this.nowChooseRowData).length === 0) {
                    MessageBox.msgbox({
                        title: '消息',
                        message: "加载失败！请检查该报告是否存在。",
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                } else {
                    this.handleResultShow();
                }
                this.forceUpdate();
            })
            .catch((err) => {
                MessageBox.msgbox({
                    title: '消息',
                    message: "加载失败！请检查该报告是否存在。",
                    type: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                });
                console.log(err);
            });
    };

    componentDidMount() {
        this.updateCheckData();
    }

    render() {
        return (
            <div>
                <Dialog
                    title={"查重结果：" + this.nowChooseRowData.name}
                    size="full"
                    visible={true}
                    onCancel={() => {
                        window.location.href = "/";
                    }}
                    closeOnPressEscape={true}
                    lockScroll={true}
                >
                    <Dialog.Body>
                        <ResultBoard data={this.nowChooseRowData} secret_change={this.state.secret_change}/>
                    </Dialog.Body>
                </Dialog>
            </div>
        );
    }
}
