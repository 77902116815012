/*
 * @Author: Garywoner
 * @Date: 2021-04-26 15:14:00
 * @Last Modified by: Garywoner
 * @Last Modified time: 2021-04-26 15:14:00
 */

import React, { Component } from "react";

import cookie from "react-cookies";

import { Select as ElementSelect, Input, Card, Button} from "element-react";

import { Row, Col } from "reactstrap";

import "./index.css";

export default class Select_pure extends Component {
  state = {
    selectValue: "",
      connectValue: [],
  };
  handleChange = (key, value) => {
    this.setState({ [key]: value });
      var pl = this.state.connectValue.indexOf(value);
    if (pl !== -1) {
        this.state.connectValue = this.state.connectValue.filter(item => item !== value);
    }
    else{
        this.state.connectValue.push(value);
    }
  };

  handleChangeUpdate = (key, value) => {
    this.handleChange(key, value);
    const {handleUpdateSuffix} = this.props;
    handleUpdateSuffix(this.state.connectValue.join());
    cookie.save("connectValue", this.state.connectValue.join(), { path: "/" });
  };

  componentDidMount(){
    const connectValue = cookie.load("connectValue");
    const {handleUpdateSuffix} = this.props;
    if(connectValue){
      const values = connectValue.split(",");
      for(let v in values){
        this.handleChange("selectValue", values[v]);
      }
    }
    handleUpdateSuffix(connectValue);
  }

  render() {
    const { title, placeholder, options, filterable} = this.props;

    const selectOption = options.map((el) => {
      return (
          <ElementSelect.Option
              key={el.value}
              label={el.label}
              value={el.value}
          />
      );
    })

    return (
      <div id="check-range-selecter">
        <Row>
          <Col lg="3" md="2" sm="2">
            <Input
              id="check-range-title"
              style={{ cursor: "default" }}
              prepend={
                <div style={{ width: "220px", color: "#172b4d" }}>{title}</div>
              }
            />
          </Col>
          <Col lg="9" md="10" sm="10">
            <ElementSelect
              placeholder={placeholder}
              filterable={filterable}
              expandTrigger="hover"
              value={this.state.selectValue}
              onChange={
                this.handleChangeUpdate.bind(this, "selectValue")}
            >
              {
                selectOption
              }
            </ElementSelect>
          </Col>
        </Row>
          <Card>
              {
                  this.state.connectValue.map(
                      (v, index) =>{ return <Button size="small" className="ml-0 mr-1 my-2" onClick={() => {
                          this.handleChangeUpdate("selectValue", v);
                      }}>{v}</Button> }
                  )
              }
          </Card>
      </div>
    );
  }
}
