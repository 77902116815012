import request from "./request.js";

export default function getBatchProgress(access_token, identity) {
  return request({
    url: "/progress",
    method: "get",
    headers: {
      access_token,
    },
    params: {
      type: "batch",
      identity,
    },
  });
}
