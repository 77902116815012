/*
 * @Author: Charles Zhang
 * @Date: 2021-03-12 13:31:44
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-13 11:09:20
 */

import React, { Component } from "react";

import { Card } from "element-react";

import { Line } from "react-chartjs-2";

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export default class StatisticChart extends Component {
  render() {
    const { lineData } = this.props;

    const dataMerge = new Array(6).fill(0);

    for (let i in lineData) {
      if (Number(i) === 0) {
        dataMerge[0] = lineData[i];
        continue;
      }

      if (Number(i) === lineData.length - 1) {
        dataMerge[5] = lineData[i];
        continue;
      }

      dataMerge[Math.floor(i / 2) + 1] += lineData[i];
    }

    const data = {
      labels: ["0", "(0,20%]", "(20%,40%]", "(40%,60%]", "(60%,80%]", "100%"],
      datasets: [
        {
          label: "该范围的人数",
          data: dataMerge,
          fill: false,
          backgroundColor: "rgb(255, 99, 132)",
          borderColor: "rgba(255, 99, 132, 0.2)",
        },
      ],
    };

    return (
      <>
        <Card className="box-card">
          <Line data={data} options={options} height={120} />
        </Card>
      </>
    );
  }
}
