/*
 * @Author: Charles Zhang
 * @Date: 2021-02-26 12:13:45
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-07 14:51:47
 */

import React, { Component } from "react";

import { Table, Progress, Loading } from "element-react";

import cookie from "react-cookies";
import PubSub from "pubsub-js";

import getCheckProgress from "api/getCheckProgress";
import ssoRefresh from "../../api/ssoRefresh";

export default class CheckTable extends Component {
  state = {
    columns: [
      {
        label: "查重任务标题",
        prop: "name",
      },
      {
        label: "当前状态",
        prop: "status",
        width: 100,
      },
      {
        label: "查重进度",
        prop: "progress",
        width: 100,
        render: (data) => {
          return (
            <Progress
              style={{ marginRight: "-20px", marginLeft: "-10px" }}
              type="line"
              percentage={data.progress}
            />
          );
        },
      },
    ],
    data: [],
    isLoading: true,
    emptyText: "目前没有查重任务",
  };

  dataPreLength = -1;
  retryTimesSetter = 5;

  updateCheckData = () => {
    if (this.retryTimes <= 0) {
      // 超过重试次数后停止加载页显示
      this.setState({ isLoading: false });
      return false;
    }

    getCheckProgress(cookie.load("ACCESS_TOKEN"), "_all")
      .then((response) => {
        try{
          if(response.data.data.message === "logout") {
            ssoRefresh();
          }
        }
        catch(e){
          console.log(e);
        }

        const check_jobs = response.data.data.result;

        const data = [];

        let flag = true;
        for (const key in check_jobs) {
          const status = check_jobs[key].status;
          if (status === "finished") {
            continue;
          }

          flag = false;
          this.retryTimes = this.retryTimesSetter;

          const name = check_jobs[key].job_name;
          const craeteTime = check_jobs[key].start_time;

          const finished_docs = check_jobs[key].progress;
          const total_docs = check_jobs[key].total_docs;

          let progress = 0;

          progress = Math.floor(
            (Number(finished_docs) / Number(total_docs)) * 99
          );

          // 读取到数据后停止加载页显示
          this.setState({ isLoading: false });

          data.unshift({ name, status, progress, craeteTime });
        }

        data.sort((a, b) => a.craeteTime - b.craeteTime);
        this.setState({ data });

        if (data.length < this.dataPreLength) {
          // 向 ResultTable 发布消息，通知更新查重任务列表
          PubSub.publish("updateResult", { isLoading: false });
        }

        this.dataPreLength = data.length;

        if (flag) {
          this.retryTimes -= 1;
        }
        setTimeout(this.updateCheckData, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.retryTimes = 1;
    this.updateCheckData();

    // 先取消订阅，避免重复触发的问题
    PubSub.unsubscribe("updateCheck");
    PubSub.subscribe("updateCheck", () => {
      console.log("接收到查重进度更新请求...");
      this.retryTimes = this.retryTimesSetter;
      this.updateCheckData();
    });
  }

  render() {
    const { columns, data, isLoading, emptyText } = this.state;

    return (
      <Loading text="正在尝试拉取查重报告列表" loading={isLoading}>
        <Table
          style={{ width: "100%" }}
          columns={columns}
          data={data}
          border={true}
          stripe={true}
          height={148}
          highlightCurrentRow={true}
          emptyText={isLoading ? "" : emptyText}
        />
      </Loading>
    );
  }
}
