import request from "./requestLongTimeOut.js";

export default function createBatch(access_token, files, file_format, batch_name, description, file_type, suffix_screen, lib_tag, organization) {
  const formData = new FormData();
  if (!organization) organization="";

  formData.append("files", files);
  formData.append("file_format", file_format);
  formData.append("file_type", file_type);
  formData.append("batch_name", batch_name);
  formData.append("description", description);
  formData.append("suffix_screen", suffix_screen);
  formData.append("lib_tag", lib_tag);
  formData.append("organization", organization);

  return request({
    url: "/batch",
    method: "post",
    headers: {
      access_token,
    },
    data: formData,
  });
}
