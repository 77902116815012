/*
 * @Author: Charles Zhang
 * @Date: 2021-02-20 16:30:05
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-11 00:03:50
 */

import React from "react";
import cookie from "react-cookies";
import PubSub from "pubsub-js";

import {Link} from "react-router-dom";

// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// import randomString from "utils/randomString";
import checkToken from "api/checkToken";
import parseToken from "api/parseToken";

// reactstrap components
import {
    Button,
    UncontrolledCollapse,
    // DropdownMenu,
    // DropdownItem,
    // DropdownToggle,
    // UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavItem,
    // NavLink as StrapLink,
    Nav,
    Container,
    Row,
    Col,
    // UncontrolledTooltip,
} from "reactstrap";

import {Form, Notification, Button as BootButton, Dialog, Input, MessageBox, Message} from "element-react";
import postToken from "api/postToken";
import defaultSettings from "../../api/settings";
import ssoRefresh from "../../api/ssoRefresh";
import urlencode from "../../utils/urlencodeUtil";

class DemoNavbar extends React.Component {
    state = {
        collapseClasses: "",
        collapseOpen: false,
        isLogin: cookie.load("isLogin") === "true",
        showHeader: "block",
        loginVisible: false,
        loginKey: cookie.load("loginKey"),
        loginOrganization: cookie.load("organization"),
        token: cookie.load("token"),
        access_token: cookie.load("ACCESS_TOKEN"),
        refresh_token: cookie.load("REFRESH_TOKEN"),
    };

    urlArgs = (query) => {
        const args = {};
        if (!query) return args;
        const pairs = query.split("&");
        for (let i = 0; i < pairs.length; i++) {
            const pos = pairs[i].indexOf("=");
            if (pos === -1) {
                continue;
            }
            const name = pairs[i].substring(0, pos);
            args[name] = pairs[i].substring(pos + 1);
        }
        return args;
    };

    emailCheck(emailAddr) {
        if ((emailAddr == null) || (emailAddr.length < 2)) {
            alert("请输入正确的邮箱！");
            return false;
        }

        // 需出现'@',且不在首字符.
        var aPos = emailAddr.indexOf("@", 1);

        if (aPos < 0) {
            alert("请输入正确的邮箱！");
            return false;
        }

        // '@'后出现'.',且不紧跟其后.
        if (emailAddr.indexOf(".", aPos + 2) < 0) {
            alert("请输入正确的邮箱！");
            return false;
        }

        return true;
    }

    hasLetter(str) {
        for (var i in str) {
            var asc = str.charCodeAt(i);
            if ((asc >= 65 && asc <= 90) || (asc >= 97 && asc <= 122)) {
                return true;
            }
        }
        return false;
    }

    notNullCheck(str) {
        if (!this.hasLetter(str) && escape(str).indexOf("%u") === -1) {
            alert("机构/组织应包括汉字或字母！");
            return false;
        }
        return true;
    }

    onChange = (key, value) => {
        this.setState({[key]: value});
    };
    openLoginSuccessMessage = (token) => {
        console.log(`用户${token}登陆成功`);
        Notification({
            message: `用户登陆成功，一秒后自动刷新`,
            type: "success",
            offset: 75,
            duration: 3000,
        });
        setTimeout(function () {
            window.location.reload([true]);
        }, 1000);
    };

    openLogoutSuccessMessage = (token) => {

        console.log(`用户 ${token} 已经登出`);
        Notification({
            message: `用户已经登出，一秒后自动刷新`,
            type: "warning",
            offset: 75,
            duration: 3000,
        });
        setTimeout(function () {
            window.location.href = "/";
        }, 1000);
    };

    handleLogin = () => {
        // checkToken(this.state.loginKey, this.state.loginOrganization)
        //     .then((reponse)=> {
        //       let token = cookie.load("token");
        //       let isLogin = cookie.load("isLogin");
        //       if (isLogin !== "true") {
        //         if(!reponse.data.data){
        //           alert("您输入的机构与组织与首次登录时不一样或者网络不稳定，请重新输入！");
        //           return false;
        //         }
        //         this.setState({ token: reponse.data.data.result });
        //       }
        //       else{
        //         this.setState({ token: "" });
        //         this.setState({ loginKey: "" });
        //         this.setState({ loginOrganization: "" });
        //       }
        //       cookie.save("token", this.state.token, { path: "/" });
        //       cookie.save("organization", this.state.loginOrganization, { path: "/" });
        //       cookie.save("loginKey", this.state.loginKey, { path: "/" });
        //       this.setState({ loginVisible: false });
        //
        //       isLogin = isLogin === "true";
        //
        //       cookie.save("isLogin", !isLogin, { path: "/" });
        //       this.setState({ isLogin: !isLogin });
        //
        //       if (!isLogin) {
        //         this.openLoginSuccessMessage(token);
        //       } else {
        //         this.openLogoutSuccessMessage(token);
        //       }
        //     })
    };

    onExiting = () => {
        this.setState({
            collapseClasses: "collapsing-out",
        });
    };

    onExited = () => {
        this.setState({
            collapseClasses: "",
        });
    };

    componentDidMount() {
        const {isLogin, access_token} = this.state;
        let headroom = new Headroom(document.getElementById("navbar-main"));
        // initialise
        headroom.init();

        if (cookie.load("isLogin") === "true") {
            this.setState({isLogin: true});
        }

        // 先取消订阅，避免重复触发的问题
        PubSub.unsubscribe("hideNavbar");
        PubSub.subscribe("hideNavbar", () => {
            this.setState({showHeader: "none"});
        });

        PubSub.unsubscribe("showNavbar");
        PubSub.subscribe("showNavbar", () => {
            this.setState({showHeader: "block"});
        });
        const sea = window.location.search.substring(1);
        const token_name = 'cg_token';
        if (sea && sea.includes(token_name)) {
            try {
                const search = this.urlArgs(sea);
                const xhr = new XMLHttpRequest();
                xhr.open('POST', defaultSettings.baseUrl + '/parse_token', false);
                xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
                xhr.send('token=' + search[token_name]);
                const responseText = xhr.responseText
                const res = JSON.parse(responseText);//获取到服务端返回的数据

                const school_id = res["organization"] ?? res["schoolid"]
                const real_name = res["username"] ?? res["realname"]
                const userid = res["userid"]
                const course = res["course"] ?? res["courses"] ?? res["coursename"]
                const organization = school_id + "-" + course;
                const raw_access_token = school_id + "-" + course + "-" + userid;
                const access_token = urlencode(raw_access_token);
                this.setState({
                    access_token: access_token,
                    isLogin: true
                });

                cookie.save("ACCESS_TOKEN", access_token, {path: "/", sameSite: "Lax"});
                cookie.save("REFRESH_TOKEN", access_token, {path: "/", sameSite: "Lax"});
                cookie.save("isLogin", true, {path: "/", sameSite: "Lax"});
                cookie.save("loginKey", userid, {path: "/", sameSite: "Lax"});
                cookie.save("organization", organization, {path: "/", sameSite: "Lax"});
                const redirect_path = window.location.pathname;
                if (redirect_path) {
                    window.location.href = redirect_path
                } else {
                    window.location.href = "/";
                }
            } catch (e) {
                console.log(e);
                cookie.save("isLogin", false, {path: "/"});
            }
        }

    }


    render() {
        const {isLogin, showHeader, loginVisible, loginKey, loginOrganization} = this.state;

        return (
            <div>

                <Dialog
                    title="请输入邮箱以及所在的机构/组织登录"
                    size="tiny"
                    visible={loginVisible && !isLogin}
                    onCancel={() => this.setState({loginVisible: false})}
                    lockScroll={false}
                >
                    <Dialog.Body>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            if (this.emailCheck(loginKey) && this.notNullCheck(loginOrganization)) {
                                this.handleLogin();
                            }
                        }
                        }>
                            <Form.Item>
                                <Input
                                    value={loginKey}
                                    style={{cursor: "default"}}
                                    onChange={this.onChange.bind(this, "loginKey")}
                                    placeholder="请输入您的邮箱"
                                    prepend={
                                        <div style={{width: "100px", color: "#172b4d"}}>
                                            邮箱
                                        </div>
                                    }
                                />
                            </Form.Item>
                            <Form.Item>
                                <Input
                                    value={loginOrganization}
                                    style={{cursor: "default"}}
                                    onChange={this.onChange.bind(this, "loginOrganization")}
                                    placeholder="请输入您所在的机构/组织"
                                    prepend={
                                        <div style={{width: "100px", color: "#172b4d"}}>
                                            机构/组织
                                        </div>
                                    }
                                />
                            </Form.Item>
                            <font size="2" style={{color: "red"}}>
                                首次登录时机构/组织请务必记住，后续登录需与邮箱匹配正确才可成功登录！
                            </font>
                        </Form>
                    </Dialog.Body>
                    <Dialog.Footer className="dialog-footer">
                        <BootButton
                            type="warning"
                            onClick={() => {
                                this.setState({loginVisible: false});
                                this.setState({loginKey: ""});
                                this.setState({loginOrganization: ""});
                            }}
                        >
                            取消
                        </BootButton>
                        <BootButton
                            type="success"
                            onClick={() => {
                                if (this.emailCheck(loginKey) && this.notNullCheck(loginOrganization)) {
                                    this.handleLogin();
                                }
                            }}
                        >
                            登录
                        </BootButton>
                    </Dialog.Footer>
                </Dialog>
                <Dialog
                    title="确认退出登录吗？"
                    size="tiny"
                    visible={loginVisible && isLogin}
                    onCancel={() => this.setState({loginVisible: false})}
                    lockScroll={false}
                >
                    <Dialog.Body>
                        <Form>
                            {/*<font size="2" style={{ color: "red" }}>*/}
                            {/*  退出登录前请记好邮箱以及机构/组织，以便下次登录。*/}
                            {/*</font>*/}
                        </Form>
                    </Dialog.Body>
                    <Dialog.Footer className="dialog-footer">
                        <BootButton
                            type="warning"
                            onClick={() => {
                                this.setState({loginVisible: false});
                            }}
                        >
                            取消
                        </BootButton>
                        <BootButton
                            type="danger"
                            onClick={() => {

                                // const xhr = new XMLHttpRequest();
                                // // xhr.open('POST', 'https://sso.eduxiji.com/auth/realms/CG/protocol/openid-connect/logout', false);
                                // // xhr.setRequestHeader("Content-Type","application/x-www-form-urlencoded;charset=UTF-8");
                                // // xhr.setRequestHeader("Authorization","bearer " + this.state.access_token);
                                // // xhr.send('client_id=cgplag&refresh_token=' + this.state.refresh_token);
                                //
                                // xhr.open('POST', defaultSettings.baseUrl + '/sso_relay', false);
                                // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
                                // xhr.send('request_action=logout&access_token=' + this.state.access_token + '&refresh_token=' + this.state.refresh_token);
                                //
                                cookie.save("isLogin", false, {path: "/"});
                                cookie.remove("ACCESS_TOKEN", {path: "/"});
                                cookie.remove("REFRESH_TOKEN", {path: "/"});
                                cookie.remove("loginKey", {path: "/"});
                                cookie.remove("organization", {path: "/"});
                                window.location.href = "/";
                                this.handleLogin();
                            }}
                        >
                            确认
                        </BootButton>
                    </Dialog.Footer>
                </Dialog>

                <header className="header-global" style={{display: showHeader}}>
                    <Navbar
                        className="navbar-main navbar-transparent navbar-light headroom"
                        expand="lg"
                        id="navbar-main"
                    >
                        <Container>
                            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                                <img
                                    alt="..."
                                    src={require("assets/img/brand/cgplag-react-white.png")}
                                />
                            </NavbarBrand>
                            <button className="navbar-toggler" id="navbar_global">
                                <span className="navbar-toggler-icon"/>
                            </button>
                            <UncontrolledCollapse
                                toggler="#navbar_global"
                                navbar
                                className={this.state.collapseClasses}
                                onExiting={this.onExiting}
                                onExited={this.onExited}
                            >
                                <div className="navbar-collapse-header">
                                    <Row>
                                        <Col className="collapse-brand" xs="6">
                                            <Link to="/">
                                                <img
                                                    alt="..."
                                                    src={require("assets/img/brand/cgplag-react-white.png")}
                                                />
                                            </Link>
                                        </Col>
                                        <Col className="collapse-close" xs="6">
                                            <button className="navbar-toggler" id="navbar_global">
                                                <span/>
                                                <span/>
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                                {/*<Nav className="navbar-nav-hover align-items-lg-center" navbar>*/}
                                {/*  <UncontrolledDropdown nav>*/}
                                {/*    <DropdownToggle nav>*/}
                                {/*      <i className="ni ni-collection d-lg-none mr-1" />*/}
                                {/*      <span className="nav-link-inner--text">Examples</span>*/}
                                {/*    </DropdownToggle>*/}
                                {/*    <DropdownMenu>*/}
                                {/*      <DropdownItem to="/landing-page" tag={Link}>*/}
                                {/*        Landing*/}
                                {/*      </DropdownItem>*/}
                                {/*      <DropdownItem to="/profile-page" tag={Link}>*/}
                                {/*        Profile*/}
                                {/*      </DropdownItem>*/}
                                {/*      <DropdownItem to="/login-page" tag={Link}>*/}
                                {/*        Login*/}
                                {/*      </DropdownItem>*/}
                                {/*      <DropdownItem to="/register-page" tag={Link}>*/}
                                {/*        Register*/}
                                {/*      </DropdownItem>*/}
                                {/*    </DropdownMenu>*/}
                                {/*  </UncontrolledDropdown>*/}
                                {/*</Nav>*/}
                                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                                    {/*<NavItem>*/}
                                    {/*  <StrapLink*/}
                                    {/*    className="nav-link-icon"*/}
                                    {/*    href="https://www.facebook.com/creativetim"*/}
                                    {/*    id="tooltip333589074"*/}
                                    {/*    target="_blank"*/}
                                    {/*  >*/}
                                    {/*    <i className="fa fa-facebook-square" />*/}
                                    {/*    <span className="nav-link-inner--text d-lg-none ml-2">*/}
                                    {/*      Facebook*/}
                                    {/*    </span>*/}
                                    {/*  </StrapLink>*/}
                                    {/*  <UncontrolledTooltip delay={0} target="tooltip333589074">*/}
                                    {/*    Like us on Facebook*/}
                                    {/*  </UncontrolledTooltip>*/}
                                    {/*</NavItem>*/}
                                    {/*<NavItem>*/}
                                    {/*  <StrapLink*/}
                                    {/*    className="nav-link-icon"*/}
                                    {/*    href="https://www.instagram.com/creativetimofficial"*/}
                                    {/*    id="tooltip356693867"*/}
                                    {/*    target="_blank"*/}
                                    {/*  >*/}
                                    {/*    <i className="fa fa-instagram" />*/}
                                    {/*    <span className="nav-link-inner--text d-lg-none ml-2">*/}
                                    {/*      Instagram*/}
                                    {/*    </span>*/}
                                    {/*  </StrapLink>*/}
                                    {/*  <UncontrolledTooltip delay={0} target="tooltip356693867">*/}
                                    {/*    Follow us on Instagram*/}
                                    {/*  </UncontrolledTooltip>*/}
                                    {/*</NavItem>*/}
                                    {/*<NavItem>*/}
                                    {/*  <StrapLink*/}
                                    {/*    className="nav-link-icon"*/}
                                    {/*    href="https://twitter.com/creativetim"*/}
                                    {/*    id="tooltip184698705"*/}
                                    {/*    target="_blank"*/}
                                    {/*  >*/}
                                    {/*    <i className="fa fa-twitter-square" />*/}
                                    {/*    <span className="nav-link-inner--text d-lg-none ml-2">*/}
                                    {/*      Twitter*/}
                                    {/*    </span>*/}
                                    {/*  </StrapLink>*/}
                                    {/*  <UncontrolledTooltip delay={0} target="tooltip184698705">*/}
                                    {/*    Follow us on Twitter*/}
                                    {/*  </UncontrolledTooltip>*/}
                                    {/*</NavItem>*/}
                                    {/*<NavItem>*/}
                                    {/*  <StrapLink*/}
                                    {/*    className="nav-link-icon"*/}
                                    {/*    href="https://github.com/creativetimofficial/argon-design-system-react"*/}
                                    {/*    id="tooltip112445449"*/}
                                    {/*    target="_blank"*/}
                                    {/*  >*/}
                                    {/*    <i className="fa fa-github" />*/}
                                    {/*    <span className="nav-link-inner--text d-lg-none ml-2">*/}
                                    {/*      Github*/}
                                    {/*    </span>*/}
                                    {/*  </StrapLink>*/}
                                    {/*  <UncontrolledTooltip delay={0} target="tooltip112445449">*/}
                                    {/*    Star us on Github*/}
                                    {/*  </UncontrolledTooltip>*/}
                                    {/*</NavItem>*/}
                                    <NavItem>
                                        <font size="2" style={{display: isLogin ? "block" : "none", color: "white"}}>
                                            用户： <strong>{loginKey} </strong> 欢迎您！
                                        </font>
                                    </NavItem>
                                    <NavItem>
                                        <font size="2" style={{display: isLogin ? "block" : "none", color: "white"}}>
                                            当前机构及课程： <strong>{loginOrganization}</strong>
                                        </font>
                                    </NavItem>
                                    <NavItem/>
                                    <NavItem>
                                        <Button
                                            className="btn-neutral btn-icon"
                                            color="warning"
                                            target="_blank"
                                            onClick={() => {
                                                fetch(require("doc/CG-Plag查重系统使用说明.pdf")).then(res => res.blob().then(blob => {
                                                    const a = document.createElement('a');
                                                    const url = window.URL.createObjectURL(blob);
                                                    const filename = 'CG-Plag查重系统使用说明.pdf';
                                                    a.href = url;
                                                    a.download = filename;
                                                    a.click();
                                                    window.URL.revokeObjectURL(url);
                                                }))
                                            }}
                                        >
                        <span className="btn-inner--icon align-text-bottom">
                          <i className="fa fa-question-circle mr-2"/>
                        </span>
                                            <span className="nav-link-inner--text ml-1">
                          使用说明
                        </span>
                                        </Button>
                                    </NavItem>
                                    <NavItem>
                                        <Button
                                            className="btn-neutral btn-icon"
                                            color="default"
                                            target="_blank"
                                            onClick={() => {
                                                if (isLogin) this.setState({loginVisible: true});
                                                // else window.location.href = 'https://sso.eduxiji.com/auth/realms/CG/protocol/openid-connect/auth?client_id=cgplag&response_type=code';
                                                else {
                                                    alert('请用教师账号登录到希冀平台，进入要查重的课程，点击"一键登录在线查重系统"按钮自动登录到本系统。此外，您可通过查阅本网页的\"使用说明\"了解更多。');
                                                }
                                            }}
                                        >
                        <span className="btn-inner--icon align-text-bottom">
                          <i className="fa fa-user-circle mr-2"/>
                        </span>
                                            <span className="nav-link-inner--text ml-1">
                          {isLogin ? "退出当前用户" : "用户登录"}
                        </span>
                                        </Button>
                                    </NavItem>
                                </Nav>
                            </UncontrolledCollapse>
                        </Container>
                    </Navbar>
                </header>
            </div>
        );
    }
}

export default DemoNavbar;
