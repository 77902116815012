/*
 * @Author: Charles Zhang
 * @Date: 2021-03-13 14:38:32
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-13 15:15:16
 */

import React, { Component } from "react";

import { Layout, Card, Button } from "element-react";

import { Alert } from "reactstrap";

import StatisticChart from "./StatisticChart";

export default class CheckResultStatistic extends Component {
  state = {
    red: false,
    yellow: false,
    green: false,
  };
  onChange = (key, value) => {
    this.setState({ [key]: value });
  };
  render() {
    let { statistics } = this.props;

    if (!statistics) {
      statistics = {
        low: "统计中",
        mid: "统计中",
        high: "统计中",
      };
    }
    else{
      try{
      const out = Math.max(Number(statistics.low), Number(statistics.mid), Number(statistics.high));
      switch(out){
        case Number(statistics.low):this.onChange("green", true);this.onChange("yellow", false);this.onChange("red", false);break;
        case Number(statistics.mid):this.onChange("yellow", true);this.onChange("green", false);this.onChange("red", false);break;
        case Number(statistics.high):this.onChange("red", true);this.onChange("yellow", false);this.onChange("green", false);break;
      }
      }
      catch (e) {
      }
    }

    return (
      <>
        <Card
          className="box-card"
          bodyStyle={{
            height: window.innerHeight - 423,
            overflow: "auto",
          }}
          header={
            <div className="clearfix">
              <span style={{ lineHeight: "36px" }} className="h6 text-muted">
                统计分析
              </span>
              <span style={{ float: "right" }}>
                {/*<Button type="primary">重新加载</Button>*/}
              </span>
            </div>
          }
        >
          <Alert color="danger" style={{ display: this.state.red ? "block" : "none" }}>
            <i className="fa fa-exclamation-triangle mr-2" />
            该批次抄袭情况 <strong>较为严重</strong> ，请重点关注。
          </Alert>
          <Alert color="warning" style={{ display: this.state.yellow ? "block" : "none" }}>
            <i className="fa fa-info-circle mr-2" />
            该批次抄袭程度 <strong> 一般 </strong> ，可继续排查。
          </Alert>
          <Alert color="success" style={{ display: this.state.green ? "block" : "none" }}>
            <i className="fa fa-thumbs-o-up mr-2" />
            该批次抄袭情况 <strong>良好</strong> ，继续保持。
          </Alert>
          <Layout.Row gutter="10">
            <Layout.Col lg="8" md="24">
              <Card className="box-card mb-3">
                <span className="text-nowrap mr-2">高重复人数：</span>
                <span className="text-danger">
                  <i className="fa fa-exclamation-triangle" /> {statistics.high}
                </span>
              </Card>
            </Layout.Col>
            <Layout.Col lg="8" md="12">
              <Card className="box-card mb-3">
                <span className="text-nowrap mr-2">中重复人数：</span>
                <span className="text-yellow">
                  <i className="fa fa-info-circle" /> {statistics.mid}
                </span>
              </Card>
            </Layout.Col>
            <Layout.Col lg="8" md="12">
              <Card className="box-card mb-3">
                <span className="text-nowrap mr-2">低重复人数：</span>
                <span className="text-success">
                  <i className="fa fa-thumbs-o-up" /> {statistics.low}
                </span>
              </Card>
            </Layout.Col>
          </Layout.Row>
          <StatisticChart lineData={statistics.chartData} />
        </Card>
      </>
    );
  }
}
