/*
 * @Author: Charles Zhang
 * @Date: 2021-02-26 11:17:05
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-16 23:17:44
 */

import React, { Component } from "react";

import { Form, Table, Loading, Dialog, Button, Input, Switch, MessageBox } from "element-react";

// reactstrap components
import { Row, Col } from "reactstrap";

import cookie from "react-cookies";
import PubSub from "pubsub-js";

import dateFormater from "utils/dateFormater";
import BatchBoard from "./BatchBoard";

import Search_lib from "components/Search_lib";
import Search_lib_private from "components/Search_lib_private";

import createCheckJob from "api/createCheckJob";
import getBatchDetail from "api/getBatchDetail";
import deleteBatch from "api/deleteBatch";
import getBatchIdByLibTag from "api/getBatchIdByLibTag";
import ssoRefresh from "../../api/ssoRefresh";

export default class BatchTable extends Component {
  state = {
    data: [],
    isLoading: true,
    emptyText: "目前没有已提交的文档",
    dialogVisible: false,
    createJobVisible: false,
    batchBoardVisible: false,
    needCheck: false,
    jobTitle: "",
    lib_tag: "",
    lib_tag_p: "",
    private_lib: true,
    with_self: true,
  };

  nowChooseRowData = {};

  handleCellMouseEnter = (row) => {
    this.nowChooseRowData = row;
  };

  handleBatchShow = () => {
    PubSub.publish("hideNavbar");
    PubSub.publish("fetchBatchResult", { id: this.nowChooseRowData.batch_id });
    this.setState({ batchBoardVisible: true });
  };

  closeBatchBoard = () => {
    PubSub.publish("showNavbar");
    this.setState({ batchBoardVisible: false });
  };

  handleBatchDelete = () => {
    this.setState({ dialogVisible: true });
  };

  handleJobCreate = () => {
    this.setState({ createJobVisible: true });
  };

  handleUpdateLibTagPrivate = (lib_tag_p) => {
    this.setState({ lib_tag_p });
  };

  doBatchDelete = () => {
    deleteBatch(cookie.load("ACCESS_TOKEN"), this.nowChooseRowData.batch_id)
      .then((response) => {
        try{
          if(response.data.data.message === "logout") {
            ssoRefresh();
          }
        }
        catch(e){
          console.log(e);
        }
        console.log(response.data);
        this.updateBatchList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doJobCreate = () => {

    const { jobTitle, lib_tag, lib_tag_p, needCheck, private_lib, with_self  } = this.state;
    // const { handleFlod } = this.props;

    const jobID = this.nowChooseRowData.batch_id;
    let search_range = "";
    getBatchIdByLibTag(private_lib? cookie.load("ACCESS_TOKEN"):"", private_lib?lib_tag_p:lib_tag)
        .then((response) => {
          try{
            if(response.data.data.message === "logout") {
              ssoRefresh();
            }
          }
          catch(e){
            console.log(e);
          }
          search_range = response.data.data.result;

    createCheckJob(cookie.load("ACCESS_TOKEN"), jobID, jobTitle, needCheck?search_range:"", with_self ).then(() => {
      try{
        if(response.data.data.message === "logout") {
          ssoRefresh();
        }
      }
      catch(e){
        console.log(e);
      }
      // 向 CheckTable 发布消息，通知更新查重任务列表
      PubSub.publish("updateCheck", { isLoading: false });
      // handleFlod();
      MessageBox.msgbox({
        title: '消息',
        message: '提交成功！两秒后自动刷新',
        type: 'success',
        showCancelButton: false,
        showConfirmButton: false,
      });
        setTimeout(function(){
          window.location.reload([true]);
        }, 2000);
    });
        })
        .catch((err) => {
          console.log(err);
          alert(err);
          this.setState({ submited: false });
        });
  };

  updateBatchList = () => {
    getBatchDetail(cookie.load("ACCESS_TOKEN"), "_all")
      .then((response) => {
        try{
          if(response.data.data.message === "logout") {
            ssoRefresh();
          }
        }
        catch(e){
          console.log(e);
        }
        const batchs = response.data.data.batchs.map((batchObj) => {
          return {
            batch_id: batchObj.batch_id.toUpperCase(),
            name: batchObj.batch_name,
            total: batchObj.total,
            time: batchObj.create_time,
            description: batchObj.description,
            lib_tag: batchObj.lib_tag,
            organization: batchObj.organization,
          };
        });

        batchs.sort((a, b) => a.time - b.time);

        for (let i in batchs) {
          // 由于 element-react 表格的index序号显示有问题，故自己创建index
          batchs[i].index = Number(i) + 1;

          // 由于状态提升至父组件，故要加入两个操作方法
          batchs[i].operation = {};
          batchs[i].operation.show = this.handleBatchShow;
          batchs[i].operation.delete = this.handleBatchDelete;
          batchs[i].operation.check = this.handleJobCreate;
        }

        this.setState({ data: batchs, isLoading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleAutoTitle = () => {
    const nowDate = dateFormater("HH:mm:ss");

    this.setState({ jobTitle: `新建查重任务-${nowDate}` });
  };

  handleUpdateLibTag = (lib_tag) => {
    this.setState({ lib_tag });
  };

  componentDidMount() {
    this.updateBatchList();

    // 先取消订阅，避免重复触发的问题
    PubSub.unsubscribe("updateBatch");
    PubSub.subscribe("updateBatch", () => {
      this.updateBatchList();
    });
  }

  render() {
    const {
      data,
      jobTitle,
      isLoading,
      emptyText,
      dialogVisible,
      createJobVisible,
      batchBoardVisible,
      needCheck,
      private_lib,
      with_self
    } = this.state;

    const { columns } = this.props;

    return (
      <div>
        <Loading text="正在尝试拉取提交文档列表" loading={isLoading}>
          <Table
            style={{ width: "100%" }}
            columns={columns}
            data={data}
            border={true}
            stripe={true}
            height={548}
            highlightCurrentRow={true}
            emptyText={isLoading ? "" : emptyText}
            onCellMouseEnter={this.handleCellMouseEnter}
          />
        </Loading>
        <Dialog
          title="确认删除"
          size="tiny"
          visible={dialogVisible}
          onCancel={() => this.setState({ dialogVisible: false })}
          lockScroll={false}
        >
          <Dialog.Body>
            <div className="mb-2">确认删除该批次？</div>
            <ul>
              <li>批次ＩＤ：{this.nowChooseRowData.batch_id}</li>
              <li>批次名称：{this.nowChooseRowData.name}</li>
              <li>文件数量：{this.nowChooseRowData.total}</li>
              <li>创建时间：{this.nowChooseRowData.time}</li>
            </ul>
          </Dialog.Body>
          <Dialog.Footer className="dialog-footer">
            <Button onClick={() => this.setState({ dialogVisible: false })}>
              取消
            </Button>
            <Button
              type="danger"
              onClick={() => {
                this.doBatchDelete();
                this.setState({ dialogVisible: false });
              }}
            >
              确定
            </Button>
          </Dialog.Footer>
        </Dialog>
        <Dialog
          title="新建查重任务"
          size="small"
          visible={createJobVisible}
          onCancel={() => this.setState({ createJobVisible: false })}
          lockScroll={false}
        >
          <Dialog.Body>
            <Form ref={(c) => (this.form = c)} model={this.state}>
              <Form.Item prop="jobTitle">
            <Input
              value={jobTitle}
              style={{ cursor: "default" }}
              onChange={this.onChange.bind(this, "jobTitle")}
              placeholder="请输入本次查重任务标题"
              prepend={
                <div style={{ width: "100px", color: "#172b4d" }}>
                  查重任务标题
                </div>
              }
              append={
                <Button type="primary" onClick={this.handleAutoTitle}>
                  自动生成标题
                </Button>
              }
            />
              </Form.Item>
              <Form.Item prop="needCheck">
          <Row>
            <Col lg="6">
            <Input
              readOnly
              style={{ cursor: "default" }}
              placeholder={
                needCheck ? "进行库查重" : "进行本批次内自我查重"
              }
              onChange={this.onChange.bind(this, "needCheck")}
              prepend={
                <div style={{ width: "100px", color: "#172b4d" }}>
                  开启库查重
                </div>
              }
              append={
                <div>
                  <Switch
                      className="mb-0"
                      style={{ display: "inline-block" }}
                      value={needCheck}
                      onChange={this.onChange.bind(this, "needCheck")}
                  />
                  <div
                      className="ml-3"
                      style={{ display: "inline-block" }}
                  />
                </div>
              }
            />
            </Col>

            <Col lg="6" style={{display: needCheck ? "block":"none"}}>
              <Input
                  readOnly
                  style={{ cursor: "default" }}
                  placeholder={
                    private_lib ? "进行私有库查重" : "进行公有库查重"
                  }
                  onChange={this.onChange.bind(this, "private_lib")}
                  prepend={
                    <div style={{ width: "100px", color: "#172b4d" }}>
                      库查重类型
                    </div>
                  }
                  append={
                    <div>
                      <Switch
                          className="mb-0"
                          style={{ display: "inline-block" }}
                          value={private_lib}
                          onChange={this.onChange.bind(this, "private_lib")}
                      />
                      <div
                          className="ml-3"
                          style={{ display: "inline-block" }}
                      />
                    </div>
                  }
              />
            </Col>

          </Row>
              </Form.Item>

              <Form.Item>
                <Row>
                  <Col lg="8" style={{display: needCheck ? "block":"none"}}>
                    <Input
                        readOnly
                        style={{ cursor: "default" }}
                        placeholder={
                          with_self ? "加入自我查重" : "只与库查重"
                        }
                        onChange={this.onChange.bind(this, "with_self")}
                        prepend={
                          <div style={{ width: "100px", color: "#172b4d" }}>
                            是否加入自我查重
                          </div>
                        }
                        append={
                          <div>
                            <Switch
                                className="mb-0"
                                style={{ display: "inline-block" }}
                                value={with_self}
                                onChange={this.onChange.bind(this, "with_self")}
                            />
                            <div
                                className="ml-3"
                                style={{ display: "inline-block" }}
                            />
                          </div>
                        }
                    />
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item style={{ display: needCheck ? (private_lib ? "none":"block"): "none" }}>
                <Row>
                  <Col lg="12">
                    <Search_lib
                        title="搜索库标签"
                        handleUpdateLibTag={this.handleUpdateLibTag}
                    />
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item style={{ display: needCheck ? (private_lib ? "block":"none") : "none" }}>
                <Row>
                  <Col lg="12">
                    <Search_lib_private
                        title="搜索库标签"
                        handleUpdateLibTag={this.handleUpdateLibTagPrivate}
                    />
                  </Col>
                </Row>
              </Form.Item>

            </Form>
          </Dialog.Body>
          <Dialog.Footer className="dialog-footer">
            <Button onClick={() => this.setState({ createJobVisible: false, jobTitle: "", lib_tag: "", lib_tag_p: "", needCheck: false, private_lib: false })}>
              取消
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if(jobTitle){
                  if (this.state.needCheck && !(this.state.private_lib?this.state.lib_tag_p:this.state.lib_tag)) {
                    alert("您已打开库查重，请添加对应的库标签！");
                    return false;
                  }
                  this.setState({ createJobVisible: false });
                  this.doJobCreate();
                  this.setState({ jobTitle: "", lib_tag: "", lib_tag_p: "", needCheck: false, private_lib: false });
                }
                else{
                  alert("查重任务标题不能为空！");
                }
              }}
            >
              确认提交
            </Button>
          </Dialog.Footer>
        </Dialog>
        <Dialog
            title={"批次名： " + this.nowChooseRowData.name}
            size="small"
            visible={batchBoardVisible}
            onCancel={this.closeBatchBoard}
            closeOnPressEscape={true}
            lockScroll={true}
        >
          <Dialog.Body>
            <BatchBoard data={this.nowChooseRowData}/>
          </Dialog.Body>
        </Dialog>
      </div>
    );
  }
}
