/*
 * @Author: Charles Zhang
 * @Date: 2021-02-24 10:38:19
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-02-24 10:56:29
 */

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd HH:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d H:m:s.S")      ==> 2006-7-2 8:9:4.18
const dateFormater = (fmt) => {
  const nowDate = new Date();

  const o = {
    "M+": nowDate.getMonth() + 1, //月份
    "d+": nowDate.getDate(), //日
    "H+": nowDate.getHours(), //小时
    "m+": nowDate.getMinutes(), //分
    "s+": nowDate.getSeconds(), //秒
    "q+": Math.floor((nowDate.getMonth() + 3) / 3), //季度
    S: nowDate.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (nowDate.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (let k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

export default dateFormater;
