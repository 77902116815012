/*
 * @Author: Charles Zhang
 * @Date: 2021-02-21 11:02:23
 * @Last Modified by: Charles Zhang
 * @Last Modified time: 2021-03-01 15:04:20
 */

import React, { Component } from "react";

// reactstrap components
import { Card, Container, Row, Col, Button } from "reactstrap";

// element-react components
// import { Button } from "element-react";

// custom components
import Description from "components/Description";
import Background from "components/Background";

import CreateJobForm from "./CreateJobForm";

export default class Update_lib extends Component {

  state = {
    docxfile:false,
    selected:false,
  };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.main.scrollTop = 0;
  }

  render() {
    console.log('Update_lib')
    const descriptionProps = {
      title: "文档/代码包入库",
      subtitle: "分库查重 提高效率",
      description:
        "设置库标签入库；" +
        "支持设置多个库标签；"+
        "支持批量入库！",
      buttons: [
        {
          // to: "/help",
          to: "/",
          color: "info",
          icon: "fa fa-arrow-circle-left",
          title: "返回主页",
        },
        {
          to: "/result",
          color: "secondary",
          icon: "fa fa-archive",
          title: "查看已提交文档/代码包",
        },
      ],
    };
    const {
      selected,
      docxfile
    } = this.state;

    return (
      <main className="profile-page" ref={(c) => (this.main = c)}>
        {/* Part1 Description and Background */}
        <div className="position-relative">
          <section className="section section-lg section-shaped pb-250">
            <Background />
            <Description {...descriptionProps} />
          </section>
        </div>
        {/* Part2 Job Create Card */}
        <section className="section section-lg pt-lg-0 mt--200">
          <Container>
            <Col className="text-center" lg="12" style={{ display: selected?"none":"block"}}>
            <Button
                className="btn-icon mb-3 mb-sm-0 mr-4"
                color="success"
                size="lg"
                onClick={() => {
                  this.setState({ docxfile: true, selected: true });
                }}
            >
            <span className="btn-inner--icon align-text-bottom mr-0">
              <i className="fa fa-rocket" />
            </span>
            <span
                  className="btn-inner--text"
                  style={{ fontSize: "1.2em" }}
              >
                          进行文档入库
            </span>
            </Button>
            <Button
                className="btn-icon mb-3 mb-sm-0 mr-4"
                color="default"
                size="lg"
                onClick={() => {
                  this.setState({ docxfile: false, selected: true });
                }}
            >
            <span className="btn-inner--icon align-text-bottom mr-0">
             <i className="fa fa-flash" />
            </span>
            <span
                  className="btn-inner--text"
                  style={{ fontSize: "1.2em" }}
              >
                          进行代码包入库
            </span>
            </Button>
            </Col>
            <Card className="card-profile shadow" style={{ display: selected?"block":"none"}}>
              <Container className="my-3">
                <Row className="justify-content-center">
                  <Col lg="12">
                    <CreateJobForm
                        docxfile={docxfile}
                    />
                  </Col>
                </Row>
              </Container>
            </Card>
          </Container>
        </section>
      </main>
    );
  }
}
